import { SharesAdminDataGrid } from "@components/Admin/UserOrganizations/SharesAdminDataGrid/SharesAdminDataGrid"
import { Box } from "@mui/material"
import { createContext, FC } from "react"
import { useParams } from "react-router-dom"

export interface SharesAdminContainerContextProps {
  userOrgId: number
}

export const SharesAdminContainerContext = createContext<SharesAdminContainerContextProps>({
  userOrgId: 1,
})

export const SharesAdminContainer: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const userOrgId = userOrganizationId ? parseInt(userOrganizationId) : 1

  return (
    <Box margin={1}>
      <SharesAdminContainerContext.Provider value={{ userOrgId }}>
        <SharesAdminDataGrid />
      </SharesAdminContainerContext.Provider>
    </Box>
  )
}

import { Autocomplete, FormControl, TextField } from "@mui/material"
import { FC, useState } from "react"

export interface FineTuningFilterParams {
  urlParamState: unknown
  setUrlParamState: (newFilterParams: Record<string, unknown>) => void
}

export interface UrlStateProps {
  fine_tuning: string
  page: number
}

export const FineTuningFilter: FC<FineTuningFilterParams> = (params) => {
  const urlParamState = params.urlParamState as UrlStateProps
  const setUrlParamState = params.setUrlParamState
  const [fineTuning, setFineTuning] = useState<string | null>(urlParamState.fine_tuning || null)

  const handleOnChange = (even: React.SyntheticEvent, value: string | null) => {
    setFineTuning(value)
    setUrlParamState({
      ...urlParamState,
      fine_tuning: value,
      page: 0,
    })
  }

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <Autocomplete
        onChange={handleOnChange}
        options={["On", "Off"]}
        renderInput={(params) => <TextField {...params} label="Fine Tuning" />}
        value={fineTuning}
      />
    </FormControl>
  )
}

import { OpportunityQueryObjectWithCampaign, urlParamsToOpportunityQueryObject } from "@api/opportunities"
import { Layout } from "@components/shared/Layout"
import useSetUrlParamState from "@components/shared/UseSetUrlParamState"
import { FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Box } from "@mui/material"
import { OpportunitiesContainerContext } from "./OpportunitiesContainer"
import { OpportunitiesAdminDataFiltersContainer } from "./OpportunitiesAdminDataFiltersContainer"
import { OpportunitiesAdminDataGrid } from "@src/components/Admin/Opportunities/DataGrid/OpportunitiesAdminDataGrid"

export const OpportunitiesAdminContainer: FC = () => {
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)
  const urlParamState = urlParamsToOpportunityQueryObject(params)
  const setUrlParamState = useSetUrlParamState<OpportunityQueryObjectWithCampaign>()

  const [terms, setTerms] = useState(new Set<string>(urlParamState.terms))
  useEffect(() => {
    setTerms(new Set<string>(urlParamState.terms))
  }, [paramsString])

  return (
    <Layout title="Opportunities">
      <OpportunitiesContainerContext.Provider value={{ params, urlParamState, setUrlParamState, terms, setTerms }}>
        <OpportunitiesAdminDataFiltersContainer />
        <Box marginTop={2}>
          <OpportunitiesAdminDataGrid />
        </Box>
      </OpportunitiesContainerContext.Provider>
    </Layout>
  )
}

import { GridColDef } from "@mui/x-data-grid"
import { Domain, Proxy } from "@src/interfaces/content-acquisition/responseObjectInterfaces"

export const configColumns: (domains?: Domain[], proxies?: Proxy[]) => GridColDef[] = (domains, proxies) => [
  { field: "id", type: "number", hideable: true },
  {
    field: "domain_id",
    headerName: "Domain",
    flex: 1,
    type: "singleSelect",
    valueOptions: domains?.map((domain) => domain) || [],
    editable: true,
    getOptionLabel: (option: Domain) => option.domain,
    getOptionValue: (option: Domain) => option.id,
  },
  {
    field: "proxy_id",
    headerName: "Proxy ID",
    flex: 0.5,
    type: "singleSelect",
    editable: true,
    valueOptions: proxies?.map((proxy) => proxy) || [],
    getOptionLabel: (option: Proxy) => option.name,
    getOptionValue: (option: Proxy) => option.id,
  },
  { field: "disabled", headerName: "Disabled", flex: 0.5, type: "boolean", editable: true },
  {
    field: "suspended_until",
    headerName: "Suspended Until",
    flex: 1,
    type: "dateTime",
    editable: false, // allow when feature is available
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
  },
  {
    field: "created_at",
    headerName: "Created",
    flex: 1,
    editable: false,
    type: "dateTime",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
  },
  {
    field: "updated_at",
    headerName: "Updated",
    flex: 1,
    editable: false,
    type: "dateTime",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
  },
]

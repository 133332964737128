import { UserOrgBookmarkClip } from "@interfaces/userOrgBookmarkClip"

const dateStringToLocaleDate = (dateString: string) => new Date(dateString).toLocaleDateString()

export const userOrgBookmarksRow = (userOrgBookmark: UserOrgBookmarkClip) => {
  return {
    id: userOrgBookmark.id,
    channel_name: userOrgBookmark.channel_name,
    created_at: dateStringToLocaleDate(userOrgBookmark.created_at),
    meeting_title: userOrgBookmark.meeting_title,
    notes: userOrgBookmark.notes,
    user_email: userOrgBookmark.user_email,
  }
}

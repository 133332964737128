import { FC, useState, useMemo, DetailedHTMLProps, InputHTMLAttributes } from "react"
import { Button, Stack } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useQueries } from "react-query"
import { DataTable } from "../../shared/DataTable"
import { useAuthAxios } from "../../../api/axiosInstance"
import { defaultLocationFilterParams } from "../../shared/config"

let prefix = "/search"
if (!process.env.REACT_APP_GOOGLE_ENV) {
  const searchHostUrl = process.env.REACT_APP_SEARCH_API_URL || "http://localhost:8000"
  prefix = `${searchHostUrl}${prefix}`
}

const Input = (inputProps: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => (
  <input {...inputProps} />
)

// styled('input') doesn't transfer props. Might be bad in this MUI version
const VisuallyHiddenInput = styled(Input)({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
})

const monthToIndex: Record<string, number> = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
}

const AdminHitsTable: FC = () => {
  const [fileContents, setFileContents] = useState("")
  const currentYear = new Date(Date.now()).getFullYear()
  const [data, months, allQueries, termToNameMap, header] = useMemo(() => {
    if (fileContents) {
      const rows = fileContents.split("\r\n")
      const months = rows[0].split(",").slice(2)
      const baseData: Record<string, number> = {}
      months.forEach((month) => (baseData[month] = 0))

      const data = new Map<string, Record<string, string | string[] | number>>()
      const referenceMap = new Map<string, string>()
      rows.slice(1).forEach((row) => {
        const dataColumns: string[] = []
        let usingQuotes = row.charAt(0) === '"'
        let end = true
        for (let i = 0; i < row.length; i += 1) {
          if (end) {
            dataColumns.push("")
            end = false
            usingQuotes = row[i] === '"'
          }
          if (usingQuotes) {
            if (row[i] !== '"') {
              dataColumns[dataColumns.length - 1] += row[i]
            } else if (row[i + 1] === ",") {
              usingQuotes = false
            }
          } else {
            if (row[i] !== ",") {
              dataColumns[dataColumns.length - 1] += row[i]
            } else {
              end = true
              usingQuotes = false
            }
          }
        }
        const terms = [dataColumns[0], ...dataColumns[1].split(", ").filter((term) => term)]
        data.set(dataColumns[0], {
          name: dataColumns[0],
          terms,
          ...baseData,
        })
        terms.forEach((term) => referenceMap.set(term, dataColumns[0]))
      })
      return [
        data,
        months,
        Array.from(data.values())
          .map(({ terms }) =>
            (terms as string[]).map((term) =>
              months.map((month) => {
                const startTime = new Date(currentYear, monthToIndex[month.toLowerCase()])
                startTime.setUTCHours(0)

                const intermediaryEndTime = new Date(currentYear, monthToIndex[month.toLowerCase()] + 1)
                intermediaryEndTime.setUTCHours(0)
                const endTime = new Date(intermediaryEndTime.valueOf() - 1)
                return {
                  term,
                  startTime,
                  endTime,
                  month,
                }
              }),
            ),
          )
          .flat(2),
        referenceMap,
        rows[0] ?? "",
      ]
    }
    return [new Map<string, Record<string, string | string[] | number>>(), [], [], new Map(), ""]
  }, [fileContents])

  const axiosInstance = useAuthAxios()
  const queryData = useQueries(
    allQueries.map(({ term, startTime, endTime }) => {
      const searchParams = new URLSearchParams()
      searchParams.set("term", term)
      searchParams.set("filter-params", btoa(JSON.stringify(defaultLocationFilterParams)))
      searchParams.append("date-range", startTime.valueOf().toString())
      searchParams.append("date-range", endTime.valueOf().toString())
      return {
        queryKey: [
          "search",
          "hits",
          JSON.stringify(defaultLocationFilterParams),
          JSON.stringify([startTime, endTime]),
          term,
        ],
        queryFn: async () => {
          const response = await (await axiosInstance).get<number>(`${prefix}/hits?${searchParams.toString()}`)

          return response.data
        },
      }
    }),
  )

  if (queryData.every(({ isLoading }) => !isLoading)) {
    allQueries.forEach((query, index) => {
      const mainTerm = termToNameMap?.get(query.term)
      if (mainTerm) {
        const termObject = data.get(mainTerm)
        const count = queryData[index].data
        if (termObject && count) {
          // this will be the same object because it is passed by reference
          termObject[query.month] = (termObject[query.month] as number) + count
        }
      }
    })
  }

  return (
    <>
      <Stack direction="row" spacing={1} marginY={2}>
        <Button component="label" variant="contained">
          Upload file
          <VisuallyHiddenInput
            type="file"
            onChange={(event) => {
              if (event.target.files) {
                const reader = new FileReader()
                reader.onload = (event) => {
                  const text = event.target?.result?.toString()
                  if (text) {
                    setFileContents(text)
                  }
                }
                reader.readAsText(event.target.files[0])
              }
            }}
          />
        </Button>
        <Button href="https://storage.googleapis.com/cloverleaf-ai-public-assets/hits-table-template.csv">
          Download template
        </Button>
        {data.size ? (
          <Button
            variant="contained"
            onClick={() => {
              let csvData = `${header}\n`
              Array.from(data.values()).forEach((datum) => {
                let rowString = `${datum.name},"${(datum.terms as string[]).slice(1).join(", ")}"`
                months.forEach((month) => (rowString += `,${datum[month]}`))
                csvData += `${rowString}\n`
              })
              const csvContent = `data:text/csv;charset=utf-8,${csvData}`
              const encodedURI = encodeURI(csvContent)
              window.open(encodedURI)
            }}
          >
            Download as CSV
          </Button>
        ) : null}
      </Stack>
      {data.size ? (
        <DataTable
          data={Array.from(data.values())}
          columnKey="name"
          columns={[{ id: "name", label: "Name" }, ...months.map((month) => ({ id: month, label: month }))]}
        />
      ) : null}
    </>
  )
}

export default AdminHitsTable

import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import {
  useFetchUserOrganization,
  useFetchUserOrganizationCreditUsages,
  useFetchUserOrganizationnTotalCreditsUsed,
} from "@api/admin/user_organizations"
import { Column, DataTable, formatDateTime } from "../../../shared/DataTable"
import { formatNumber } from "../../../shared/DataTable/formatters"
import { Stack, TablePagination, Typography } from "@mui/material"
import { TotalCredits } from "./TotalCredits"
import { useAuthAxios } from "@api/axiosInstance"

export const CreditUsages: FC = () => {
  const [page, setPage] = useState(1)
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: creditUsages } = useFetchUserOrganizationCreditUsages(userOrganizationId, page)
  const { data: totalCreditsUsed } = useFetchUserOrganizationnTotalCreditsUsed(userOrganizationId)
  const { data: userOrganization } = useFetchUserOrganization(userOrganizationId)
  const axiosInstance = useAuthAxios()

  const buttonAction = async (briefingId: string) => {
    const response = await (
      await axiosInstance
    ).get(`admin/briefings/${briefingId}/download`, {
      responseType: "blob",
    })

    const href = URL.createObjectURL(response.data)

    const link = document.createElement("a")
    link.href = href
    link.setAttribute("download", `briefing_${briefingId}.pdf`)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  const columns: Column[] = [
    { id: "email", label: "Email" },
    {
      id: "briefing_id",
      label: "Briefing",
      buttonTitle: "Download",
      buttonAction: (data) => buttonAction(data.briefing_id),
    },
    { id: "title", label: "Meeting", link: (data) => data.url },
    { id: "credit_amount", label: "Credit Amount", formatter: formatNumber },
    { id: "created_at", label: "Date", formatter: formatDateTime },
  ]

  const used_percent =
    totalCreditsUsed && userOrganization?.total_credits
      ? ((totalCreditsUsed / userOrganization.total_credits) * 100).toFixed(2)
      : 0

  return (
    <Stack>
      <Stack spacing={2} padding={1}>
        <TotalCredits />
        <Typography>{`Credits Used: ${totalCreditsUsed?.toFixed(2)} (${used_percent}%)`}</Typography>
      </Stack>
      <Stack margin={{ md: 2 }}>
        <DataTable data={creditUsages?.credit_usages || []} columns={columns} columnKey={"id"} />
        <TablePagination
          count={creditUsages?.total_records || 0}
          page={page - 1}
          rowsPerPage={50}
          rowsPerPageOptions={[50]}
          onPageChange={(_, page) => setPage(page + 1)}
        />
      </Stack>
    </Stack>
  )
}

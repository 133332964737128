import { unpackSetters } from "@src/components/shared/unpackSetters"
import { FC, useContext } from "react"
import { OrganizationFormContext } from "./Context"
import { FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material"
import { MediaType, StorageType, ProxyType } from "@src/interfaces/organization"
import { useFetchOrganizationMetadata } from "@src/api/organizations"

export const DownloadOptions: FC = () => {
  const { data: orgMetadataData } = useFetchOrganizationMetadata()
  const { value: orgValue, setValue: setOrgValue } = useContext(OrganizationFormContext)

  const { storageType, mediaType, proxyType } = orgValue
  const { setStorageType, setMediaType, setProxyType } = unpackSetters(orgValue, setOrgValue)

  return (
    <>
      <Typography variant="h3">Download Options</Typography>
      <FormControl fullWidth>
        <InputLabel id="storage-opts-input-label">Storage Type</InputLabel>
        <Select
          value={storageType}
          onChange={(event) => setStorageType(event.target.value as StorageType)}
          labelId="storage-opts-select-label"
          label="Storage Type"
        >
          {orgMetadataData?.storage_persistence_type.map((type) => (
            <MenuItem key={type.id} value={type.value}>
              {type.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="media-opts-input-label">Media Type</InputLabel>
        <Select
          value={mediaType}
          onChange={(event) => setMediaType(event.target.value as MediaType)}
          labelId="media-opts-select-label"
          label="Media Type"
        >
          {orgMetadataData?.media_type.map((type) => (
            <MenuItem key={type.id} value={type.value}>
              {type.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="proxy-opts-input-label">Proxy Type</InputLabel>
        <Select
          value={proxyType}
          onChange={(event) => setProxyType(event.target.value as ProxyType)}
          labelId="proxy-opts-select-label"
          label="Proxy Type"
        >
          {orgMetadataData?.proxy_type.map((type) => (
            <MenuItem key={type.id} value={type.value}>
              {type.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

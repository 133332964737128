import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import DateRangePicker from "rsuite/DateRangePicker"
import "rsuite/dist/rsuite.min.css"
import { startOfDay, endOfDay, addDays } from "date-fns"
import { defaultDateRange } from "../shared/config"
import { RangeType, DateRange } from "rsuite/DateRangePicker"
import { Paper, SxProps, Theme, Typography } from "@mui/material"
import { ClickAwayListener } from "@mui/material" // See https://mui.com/material-ui/react-click-away-listener/
import { TypeAttributes } from "rsuite/esm/internals/types"
import { useDebouncedCallback } from "use-debounce"

const ranges: RangeType[] = [
  {
    label: "7 Days Ago",
    value: [startOfDay(addDays(new Date(), -7)), endOfDay(new Date())],
  },
  {
    label: "30 Days Ago",
    value: [startOfDay(addDays(new Date(), -30)), endOfDay(new Date())],
  },
  {
    label: "90 Days Ago",
    value: [startOfDay(addDays(new Date(), -90)), endOfDay(new Date())],
  },
]

export type Placement =
  | "bottomStart"
  | "bottomEnd"
  | "topStart"
  | "topEnd"
  | "leftStart"
  | "leftEnd"
  | "rightStart"
  | "rightEnd"
  | "auto"
  | "autoVerticalStart"
  | "autoVerticalEnd"
  | "autoHorizontalStart"
  | "autoHorizontalEnd"

export const DatePicker: FC<{
  searchDateRange: [Date, Date] | null
  setSearchDateRange: Dispatch<SetStateAction<[Date, Date]>>
  size?: TypeAttributes.Size
  placement?: Placement
  onOk?: () => void
  sx?: SxProps<Theme>
}> = ({ searchDateRange, setSearchDateRange, size = "lg", placement = "bottomStart", onOk, sx }) => {
  const [pickingFirstDate, setPickingFirstDate] = useState(true)
  const [localDateRange, setLocalDateRange] = useState(defaultDateRange)
  const [helperText, setHelperText] = useState<string>()

  const validateValue = (value: DateRange | null) => {
    if (value && value[0] instanceof Date && value[1] instanceof Date) {
      // Ensure the start date is before the end date
      if (value[0].valueOf() > value[1].valueOf()) {
        return false
      }
      return true
    }
    return false
  }
  const handleChange = useDebouncedCallback((value: DateRange) => {
    if (validateValue(value)) {
      setSearchDateRange(value)
    } else {
      setHelperText("Invalid Date Range")
      setSearchDateRange(defaultDateRange)
    }
  }, 1200)

  const resetHelperText = () => {
    if (helperText) {
      setTimeout(() => {
        setHelperText(undefined)
      }, 1500)
    }
  }

  useEffect(() => {
    if (searchDateRange) {
      resetHelperText()
      setLocalDateRange(searchDateRange)
    }
  }, [searchDateRange])

  return (
    <>
      <ClickAwayListener onClickAway={() => {}}>
        <Paper
          elevation={0}
          sx={{
            borderRadius: "29px",
            backgroundColor: "neutral.light",
            border: "1px solid #c4c4c4",
            overflow: "hidden",
            ...sx,
          }}
        >
          <DateRangePicker
            appearance="subtle"
            defaultValue={defaultDateRange}
            onChange={(value: DateRange | null) => {
              if (value) {
                setLocalDateRange(value)
                handleChange(value)
              }
            }}
            size={size}
            menuStyle={{ zIndex: 9999 }}
            value={localDateRange}
            ranges={ranges}
            placeholder="Choose Date Range"
            format="MM/dd/yyyy"
            cleanable={false}
            placement={placement}
            character=" - "
            onSelect={(date, event) => {
              if (pickingFirstDate) {
                setSearchDateRange([startOfDay(date), endOfDay(date)])
                setPickingFirstDate(false)
              } else {
                setSearchDateRange(([prevVal0]: [Date, Date]) => {
                  if (date.valueOf() < prevVal0.valueOf()) {
                    return [startOfDay(date), endOfDay(prevVal0)] as [Date, Date]
                  } else {
                    return [startOfDay(prevVal0), endOfDay(date)] as [Date, Date]
                  }
                })
                setPickingFirstDate(true)
                event?.stopPropagation()
              }
            }}
            onOk={(value: DateRange | null, event) => {
              if (onOk) {
                onOk()
              }
              event.stopPropagation()
            }}
            onOpen={() => setPickingFirstDate(true)}
          />
        </Paper>
      </ClickAwayListener>
      {helperText && <Typography sx={{ color: "error" }}>{helperText}</Typography>}
    </>
  )
}

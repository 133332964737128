import { useQuery, useMutation, UseQueryResult, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { Transcript } from "../../interfaces/transcript"
import { Briefing } from "../../interfaces/briefing"
import { Voice } from "../../interfaces/voice"
import { BaseMeeting } from "@src/interfaces/meeting"

const prefix = "/admin/meetings"

export interface AdminFetchMeetingsVeronikaResponse {
  id: number
  title: string
  started_at: string
  transcribed_at: string
  failed_at: string
  retries: number
}

export interface AdminFetchMeetingsResponse {
  meetings: BaseMeeting[]
  total: number
}

type AdminFetchMeetingsParamsBase = {
  meetingIds?: number[]
}

interface AdminFetchMeetingsParamsWithVeronika extends AdminFetchMeetingsParamsBase {
  veronika: true
}

interface AdminFetchMeetingsParamsWithoutVeronika extends AdminFetchMeetingsParamsBase {
  veronika?: false
}

export function useFetchMeetings(
  params: AdminFetchMeetingsParamsWithVeronika,
): UseQueryResult<AdminFetchMeetingsVeronikaResponse[], unknown>
export function useFetchMeetings(
  params: AdminFetchMeetingsParamsWithoutVeronika,
): UseQueryResult<AdminFetchMeetingsResponse, unknown>

export function useFetchMeetings({
  veronika,
  meetingIds,
}: AdminFetchMeetingsParamsWithVeronika | AdminFetchMeetingsParamsWithoutVeronika) {
  const axiosInstance = useAuthAxios()

  const queryParams = new URLSearchParams()
  if (veronika) {
    queryParams.append("veronika", veronika.toString())
  }
  if (meetingIds) {
    meetingIds.forEach((id) => {
      queryParams.append("meetingIds[]", id.toString())
    })
  }

  return useQuery(["meetings", meetingIds], async () => {
    const response = await (
      await axiosInstance
    ).get<AdminFetchMeetingsResponse | AdminFetchMeetingsVeronikaResponse[]>(`${prefix}?${queryParams.toString()}`)
    if (veronika) {
      return response.data as AdminFetchMeetingsVeronikaResponse[]
    }
    return response.data as AdminFetchMeetingsResponse
  })
}

export const useFetchMeetingTranscripts = (id: number | string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["transcripts", { meeting_id: id.toString(), transcription_service_version: null }], async () => {
    const response = await (await axiosInstance).get<Transcript[]>(`${prefix}/${id}/transcripts`)

    return response.data
  })
}

export const useGenerateTranscripts = (id: number | string) => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post(`${prefix}/${id}/generate_transcript`)
  })
}

export const getMeetingBriefings = (id: number | string, userId: number | null) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", id, "briefings", userId],
    async () => {
      return (await (await axiosInstance).get<Briefing[]>(`${prefix}/${id}/briefings?user_id=${userId}`)).data
    },
    {
      enabled: userId !== null,
    },
  )
}

export const useGenerateGeneralBriefings = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (params: { id: number[] }) => {
    return (await axiosInstance).post(`${prefix}/generate_general_briefing`, params)
  })
}

export const getMeetingVoices = (id: unknown) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["voices", { meeting_id: id }],
    async () => {
      return (await (await axiosInstance).get<(Voice & { speaker: string })[]>(`${prefix}/${id}/voices`)).data
    },
    {
      enabled: Boolean(id),
    },
  )
}

export const useRunIdentifySpeakers = (id: unknown, onSuccess?: () => void, onError?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).post(`${prefix}/${id}/identify_speakers`)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["voices", { meeting_id: id }])
        queryClient.invalidateQueries(["admin", "persons"])
      },
      onError: () => {
        if (onError) {
          onError()
        }
      },
    },
  )
}

import { FC, useState } from "react"
import { Button } from "@mui/material"
import { useParams } from "react-router-dom"
import { FetchedCampaign, useFetchUserOrganizationUsers } from "@api/admin/user_organizations"
import { AssignDialogMenu } from "@components/Campaigns/AssignDialogMenu"
import { UnassignDialogMenu } from "@components/Campaigns/UnassignDialogMenu"
import { useAssignCampaign, useUnassignCampaign } from "@api/campaigns"
import { CampaignDialog } from "@components/shared/CampaignDialog"
import { EmailCadence } from "@src/interfaces/campaign"
import { User } from "@interfaces/user"
import { useToast } from "@components/shared/ToastHook"

export const CampaignAssignment: FC<{ campaign: FetchedCampaign }> = ({ campaign }) => {
  const setToast = useToast()
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { mutate: assignCampaignToUser, isSuccess: assignSuccess } = useAssignCampaign(() => {
    setToast("Campaign assigned successfully", undefined, undefined, "info")
  })
  const { mutate: unassignCampaignToUser, isSuccess: unassignSuccess } = useUnassignCampaign(() => {
    setToast("Campaign unassigned successfully", undefined, undefined, "info")
  })
  const { data } = useFetchUserOrganizationUsers(userOrganizationId)
  const [assignDialogOpen, setAssignDialogOpen] = useState(false)
  const [unassignDialogOpen, setUnssignDialogOpen] = useState(false)
  const [selectedAssignUsers, setSelectedAssignUsers] = useState<User[]>([])
  const [selectedUnassignUsers, setSelectedUnassignUsers] = useState<User[]>([])
  const [emailCadence, setEmailCadence] = useState<EmailCadence>("Unsubscribed")
  const [assignDialogConfirmOpen, setAssignDialogConfirmOpen] = useState(false)
  const [unassignDialogConfirmOpen, setUnassignDialogConfirmOpen] = useState(false)

  const handleClose = () => {
    setAssignDialogConfirmOpen(false)
    setUnassignDialogConfirmOpen(false)
    setSelectedAssignUsers([])
    setSelectedUnassignUsers(campaign?.assignees)
    setEmailCadence("Unsubscribed")
  }

  return (
    <>
      <Button onClick={() => setAssignDialogOpen(true)}>Assign</Button>
      {campaign?.assignees?.length > 0 && (
        <Button
          onClick={() => {
            setSelectedUnassignUsers(campaign?.assignees)
            setUnssignDialogOpen(true)
          }}
        >
          Unassign
        </Button>
      )}
      <AssignDialogMenu
        handleClose={() => {
          setAssignDialogOpen(false)
        }}
        isOpen={assignDialogOpen}
        availableUsers={data}
        selectedUsers={selectedAssignUsers}
        setSelectedUsers={setSelectedAssignUsers}
        emailCadence={emailCadence}
        setEmailCadence={setEmailCadence}
        onSave={() => {
          setAssignDialogOpen(false)
          setAssignDialogConfirmOpen(true)
        }}
        assignees={campaign.assignees || []}
      />
      <CampaignDialog
        dialogOpen={assignDialogConfirmOpen}
        setDialogOpen={setAssignDialogConfirmOpen}
        onComplete={() => handleClose()}
        onSubmit={() =>
          assignCampaignToUser({
            userIds: selectedAssignUsers.map((user) => user.id.toString()),
            campaignIds: [campaign.id.toString()],
            emailCadence: emailCadence,
          })
        }
        requestStatus={assignSuccess}
        dialogAction="assign"
      />
      <UnassignDialogMenu
        handleClose={() => {
          setUnssignDialogOpen(false)
          setUnassignDialogConfirmOpen(false)
        }}
        isOpen={unassignDialogOpen}
        availableUsers={campaign.assignees}
        onSave={() => {
          setUnssignDialogOpen(false)
          setUnassignDialogConfirmOpen(true)
        }}
        selectedUsers={selectedUnassignUsers}
        setSelectedUsers={setSelectedUnassignUsers}
      />
      <CampaignDialog
        dialogOpen={unassignDialogConfirmOpen}
        setDialogOpen={setUnassignDialogConfirmOpen}
        onComplete={() => handleClose()}
        onSubmit={() => {
          unassignCampaignToUser({
            userIds: selectedUnassignUsers.map((user) => user.id.toString()),
            campaignIds: [campaign.id.toString()],
          })
        }}
        requestStatus={unassignSuccess}
        dialogAction="unassign"
      />
    </>
  )
}

import { useFetchUserOrgBookmarkClips } from "@api/admin/clip_shares"
import { BookmarksAdminContainerContext } from "@src/containers/BookmarksAdminContainer"
import { userOrgBookmarksColumns } from "@src/dataGridSchema/userOrgBookmarks/userOrgBookmarksColumns"
import { userOrgBookmarksRow } from "@src/dataGridSchema/userOrgBookmarks/userOrgBookmarksRows"
import { DataGrid, GridPaginationModel, GridSortModel } from "@mui/x-data-grid"
import { FC, useContext, useMemo, useState } from "react"
import { Toolbar } from "./Toolbar"

export interface DataGridParams {
  page?: number
  sortDir?: string | null
  sortField?: string
}

export const BookmarksAdminDataGrid: FC = () => {
  const { userOrgId } = useContext(BookmarksAdminContainerContext)
  const [dataGridParams, setDataGridParams] = useState<DataGridParams>({
    page: 0,
    sortDir: "desc",
    sortField: "created_at",
  })
  const { data, isLoading } = useFetchUserOrgBookmarkClips({ userOrgId, ...dataGridParams })

  let paginationModel: GridPaginationModel = {
    page: dataGridParams.page ?? 0,
    pageSize: 25,
  }
  const setPaginationModel = (model: GridPaginationModel) => {
    paginationModel = model
  }

  const rows = useMemo(() => {
    if (data) {
      return data.user_org_bookmark_clips.map((userOrgBookmark) => userOrgBookmarksRow(userOrgBookmark))
    }
    return []
  }, [data])

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model)
    setDataGridParams({ ...dataGridParams, page: model.page })
  }

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length > 0) {
      setDataGridParams({
        ...dataGridParams,
        page: 0,
        sortDir: model[0].sort,
        sortField: model[0].field,
      })
    }
  }

  return (
    <DataGrid
      autoHeight
      columns={userOrgBookmarksColumns}
      disableColumnFilter
      disableRowSelectionOnClick
      getRowHeight={() => "auto"}
      initialState={{
        sorting: {
          sortModel: [{ field: "created_at", sort: "desc" }],
        },
      }}
      loading={isLoading}
      onPaginationModelChange={handlePaginationModelChange}
      onSortModelChange={handleSortModelChange}
      paginationMode="server"
      paginationModel={paginationModel}
      pageSizeOptions={[25]}
      rows={rows}
      rowCount={data?.total ?? -1}
      slots={{ toolbar: Toolbar }}
      sortingMode="server"
    />
  )
}

import { FC, useEffect, useMemo, useState } from "react"
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material"
import { FetchedCampaign, useFetchCampaigns } from "@api/admin/user_organizations"
import {
  DataGrid,
  GridColDef,
  GridInputRowSelectionModel,
  GridPaginationModel,
  GridRowsProp,
  GridSortDirection,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarProps,
} from "@mui/x-data-grid"
import SearchIcon from "@mui/icons-material/Search"
import { UserOrganizationPageParams, UserOrganizationTabs } from "../UserOrganizations/UserOrganizationPage"
import useSetUrlParamState from "@components/shared/UseSetUrlParamState"
import { useLocation } from "react-router-dom"
import { CampaignSendEmailButton } from "../UserOrganizations/Campaigns/CampaignSendEmail"
import { formatDateTime } from "@components/shared/DataTable"
import { CampaignAssignment } from "../UserOrganizations/Campaigns/CampaignAssignment"
import { CampaignEditButton } from "../UserOrganizations/Campaigns/CampaignEditButton"
import { CampaignSubscriberComponent } from "../UserOrganizations/Campaigns/CampaignSubscriberComponent"

export type AdminCampaignsPageParams = {
  count?: number
  page?: number
  sort_by?: string
  sort_order?: string
  user_organization_id?: string
  name?: string
} & UserOrganizationPageParams

export interface CampaignListProps {
  userOrganizationId: number
}

export interface CustomToolbarProps {
  props: GridToolbarProps
  filterParams: AdminCampaignsPageParams
  setFilterParams: (params: AdminCampaignsPageParams) => void
}

function CustomToolbar({ props, filterParams, setFilterParams }: CustomToolbarProps) {
  const [searchName, setSearchName] = useState<string | undefined>(filterParams.name || undefined)
  return (
    <GridToolbarContainer>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Name search</InputLabel>
        <OutlinedInput
          value={searchName}
          onChange={(event) => setSearchName(event.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              setFilterParams({ ...filterParams, name: searchName, page: 0 })
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setFilterParams({ ...filterParams, name: searchName, page: 0 })} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <GridToolbarColumnsButton />
      <Box sx={{ flexGrow: 1 }}>
        <CampaignEditButton variant="text">Create Campaign</CampaignEditButton>
      </Box>
    </GridToolbarContainer>
  )
}

export const CampaignList: FC<CampaignListProps> = ({ userOrganizationId }) => {
  const filterParams = new URLSearchParams(useLocation().search)
  const { data, isLoading } = useFetchCampaigns(userOrganizationId, filterParams)

  const setFilterParams = useSetUrlParamState<AdminCampaignsPageParams>()

  const campaignFilterParams: AdminCampaignsPageParams = useMemo(
    () => ({
      name: filterParams.get("name") || undefined,
      page: parseInt(filterParams.get("page") || "0"),
      count: parseInt(filterParams.get("count") || "25"),
      title: filterParams.get("title") || undefined,
      organization: filterParams.get("organization") || undefined,
      sort_by: filterParams.get("sort_by") || undefined,
      sort_order: filterParams.get("sort_order") || undefined,
      tab: (filterParams.get("tab") as UserOrganizationTabs) || UserOrganizationTabs.Campaigns,
    }),
    [filterParams],
  )

  const getCampaignById = (id: number): FetchedCampaign | undefined => {
    return data?.campaigns.find((campaign) => campaign.id === id)
  }

  const [selectionModel, setSelectionModel] = useState<GridInputRowSelectionModel>()

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: campaignFilterParams.count || 25,
    page: campaignFilterParams.page || 0,
  })

  const [sortingModel, setSortingModel] = useState<GridSortModel>(
    campaignFilterParams.sort_by
      ? [
          {
            field: campaignFilterParams.sort_by,
            sort: campaignFilterParams.sort_order ? (campaignFilterParams.sort_order as GridSortDirection) : "asc",
          },
        ]
      : [],
  )

  const handlePaginationChange = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
    setFilterParams({
      ...campaignFilterParams,
      page: paginationModel.page,
      count: paginationModel.pageSize,
    })
  }

  const handleSortingChange = (sortingModel: GridSortModel) => {
    setSortingModel(sortingModel)
    setPaginationModel({ ...paginationModel, page: 0 })
    setFilterParams({
      ...campaignFilterParams,
      sort_by: sortingModel[0]?.field,
      sort_order: sortingModel[0]?.sort || "asc",
      page: 0,
    })
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderCell: (params) => {
        return params.row.name
        // TODO - add support for super admin get all campaigns on search page to allow non org campaigns to be viewed
        // This will allow us to link to search page to view campaign but currently search only pulls in org campaigns
        if (params.row.discarded_at) {
          return params.row.name
        }
        const urlSearchParams = new URLSearchParams()
        urlSearchParams.set("campaign-id", params.row.id.toString())
        return <Link href={`/?${urlSearchParams}`}>{params.row.name}</Link>
      },
    },
    { field: "created_by", headerName: "Created By", width: 175, sortable: false },
    {
      field: "send email to",
      headerName: "Send Email To",
      width: 250,
      renderCell: (params) => {
        return <CampaignSendEmailButton campaignId={params.row.id as number} />
      },
      sortable: false,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 75,
      sortable: false,
      renderCell: (params) => {
        const campaign = getCampaignById(params.row.id)
        if (!campaign) {
          return null
        }
        return <CampaignEditButton initialData={campaign}>Edit</CampaignEditButton>
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "assign",
      headerName: "Assign",
      width: 200,
      renderCell: (params) => {
        const campaign = getCampaignById(params.row.id)
        if (!campaign) {
          return null
        }
        return <CampaignAssignment campaign={campaign} />
      },
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "subscribers",
      headerName: "Subscribers",
      width: 150,
      renderCell: (params) => {
        const campaign = getCampaignById(params.row.id)
        if (!campaign) {
          return null
        }
        return <CampaignSubscriberComponent campaign={campaign} />
      },
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    { field: "created_at", headerName: "Created At", width: 175 },
    { field: "updated_at", headerName: "Updated At", width: 175 },
    { field: "discarded_at", headerName: "Discarded At", width: 175 },
  ]

  const [rows, setRows] = useState<GridRowsProp>()

  useEffect(() => {
    if (data) {
      setRows(
        data.campaigns.map((campaign) => ({
          id: campaign.id,
          name: campaign.name,
          created_at: formatDateTime(campaign.created_at),
          updated_at: formatDateTime(campaign.updated_at),
          created_by: campaign.user?.email || "unknown",
          discarded_at: campaign.discarded_at ? formatDateTime(campaign.discarded_at) : "",
        })),
      )
    }
  }, [data])

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      rowHeight={80}
      // checkboxSelection
      sx={{
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
      }}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      paginationMode="server"
      sortingMode="server"
      paginationModel={paginationModel}
      sortModel={sortingModel}
      onRowSelectionModelChange={(newSelection) => {
        setSelectionModel(newSelection)
      }}
      slots={{
        toolbar: (props) => (
          <CustomToolbar props={props} filterParams={campaignFilterParams} setFilterParams={setFilterParams} />
        ),
      }}
      slotProps={{
        toolbar: {
          filterParams,
          setFilterParams,
        },
      }}
      rowSelectionModel={selectionModel}
      autoHeight={true}
      onSortModelChange={handleSortingChange}
      onPaginationModelChange={handlePaginationChange}
      rowCount={data?.total ?? -1}
    />
  )
}

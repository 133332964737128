import { useAuthAxios } from "@src/api/axiosInstance"
import { QueryParamsBuilder } from "@src/interfaces/content-acquisition/queryParamsBuilder"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { ProxyDomainConfig } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { useMutation, useQuery, useQueryClient } from "react-query"

const resourcePath = `/content-acquisition/proxy_domain_config`

// do not copy paste this method, it bases the request body off of a response object, define the request body instead
export const useCreateProxyDomainConfig = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: Partial<ProxyDomainConfig>) => {
      const response = await (await axiosInstance).post<ProxyDomainConfig>(resourcePath, requestBody)
      return response.data
    },
    {
      onSuccess: (_response) => {
        queryClient.invalidateQueries(["proxy_domain_config"])
      },
    },
  )
}

// do not copy paste this method, it bases the request body off of a response object, define the request body instead
export const useUpdateProxyDomainConfig = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: Partial<ProxyDomainConfig>) => {
      const response = await (
        await axiosInstance
      ).put<ProxyDomainConfig>(`${resourcePath}/${requestBody.id}`, requestBody)
      return response.data
    },
    {
      onSuccess: (_response) => {
        queryClient.invalidateQueries(["proxy_domain_config"])
      },
    },
  )
}

export const useDeleteProxyDomainConfig = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: number) => {
      const response = await (await axiosInstance).delete<ProxyDomainConfig>(`${resourcePath}/${id}`)
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["proxy_domain_config"])
      },
    },
  )
}

export const useFetchProxyDomainConfigs = (queryParams?: CommonUrlParams) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const params = paramBuilder.build(queryParams)
  const uri = `${resourcePath}${params}`

  return useQuery("proxy_domain_config", async () => {
    const response = await (await axiosInstance).get<ProxyDomainConfig[]>(uri)

    return response.data
  })
}

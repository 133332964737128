import { Domain } from "@interfaces/content-acquisition/responseObjectInterfaces"

export const toDomainRows = (
  domain: Domain,
  jobSuccessTotals: { [key: string]: { total: number; success: number } } | undefined,
) => {
  const totals = { total: 0, success: 0 }
  if (jobSuccessTotals && jobSuccessTotals[domain.id]) {
    totals.total = jobSuccessTotals[domain.id]?.total || 0
    totals.success = jobSuccessTotals[domain.id]?.success || 0
    const percentage = totals.total === 0 ? 0 : totals.success / totals.total
    return { ...domain, total: totals?.total, successRate: percentage }
  } else {
    return domain
  }
}

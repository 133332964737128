import { FC, useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { jobColumns } from "./columns"
import { theme } from "@src/theme"
import { useFetchContentAcquisitionJobs } from "@src/api/admin/content-acquisition/jobs"
import { Job, Video } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { useFetchScheduledVideos } from "@src/api/admin/content-acquisition/videos"
import { useFetchDomains } from "@api/admin/content-acquisition/domains"

export const JobTable: FC<{ videoSelected?: Video }> = ({ videoSelected }) => {
  const videoIdByUrlQueryParams: CommonUrlParams = {
    attributeFilters: {
      url: videoSelected?.url || "",
    },
    associations: ["jobs"],
  }

  const { data: videos } = useFetchScheduledVideos(videoIdByUrlQueryParams, !!videoSelected)
  const { data: jobs, isLoading } = useFetchContentAcquisitionJobs()
  const { data: domains } = useFetchDomains()
  const [rows, setRows] = useState<Job[]>([])

  useEffect(() => {
    if (!jobs) return
    if (!videos) return setRows(jobs)

    const jobsByVideo = videos.map((video) => video?.jobs)
    setRows(jobsByVideo ? jobsByVideo.flat().filter((job): job is Job => job !== undefined) : [])
  }, [jobs, videos])

  return (
    <DataGrid
      autoHeight
      sx={{
        '.MuiDataGrid-booleanCell[data-value="true"]': {
          color: theme.palette.success.main,
        },
        '.MuiDataGrid-booleanCell[data-value="false"]': {
          color: "red",
        },
        marginTop: 2,
        marginBottom: 2,
      }}
      density="compact"
      columns={jobColumns(domains).map((column) => ({ ...column, headerClassName: "super-app-theme--header" }))}
      rows={rows}
      loading={isLoading}
      initialState={{
        columns: {
          columnVisibilityModel: {
            updated_at: false,
          },
        },
        pagination: { paginationModel: { pageSize: 25 } },
      }}
    />
  )
}

import { FC, useState } from "react"
import { Button, TextField, Box } from "@mui/material"
import { useSendCampaignEmail } from "@api/admin/campaigns"
import { Confirm } from "@components/shared/Confirm"

export const CampaignSendEmailButton: FC<{ campaignId: number }> = ({ campaignId }) => {
  const [emailTo, setEmailTo] = useState("")
  const { mutate: sendEmail } = useSendCampaignEmail()
  const [showEmailConfirm, setShowEmailConfirm] = useState(false)

  const onEmailSend = () => {
    setShowEmailConfirm(false)
    sendEmail({ emailTo, id: campaignId.toString() })
  }

  return (
    <Box display="flex" alignItems="center" height={"100%"}>
      <TextField
        sx={{ flexGrow: 1 }}
        size="small"
        value={emailTo}
        onChange={(event) => setEmailTo(event.target.value)}
        label="Send Email To:"
      />
      <Button size="small" onClick={() => setShowEmailConfirm(true)}>
        Send
      </Button>
      <Confirm
        open={showEmailConfirm}
        onClose={() => setShowEmailConfirm(false)}
        onSucceed={onEmailSend}
        title="Are you sure you want to send the email?"
      />
    </Box>
  )
}

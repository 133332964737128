import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { Opportunity } from "@src/interfaces/opportunity"
import { NaicsCode } from "@src/interfaces/naicsCode"
import { useIsOpportunitiesEnabled } from "./users"
import { UrlBuilder } from "./urlBuilder"

const prefix = "/opportunities"

export interface OpportunityData {
  states: number[]
  cities: number[]
  counties: number[]
  organizations: number[]
  naics_code: string[] // REMOVE THIS ONCE WE REMOVE NAICS CODES OPP SEARCH FEATURE FLAG
  industry: string[]
  channel_types: number[]
  city_population?: [number, number]
  county_population?: [number, number]
  city_income?: [number, number]
  county_income?: [number, number]
  terms: string[]
}

export interface OpportunityQueryObject extends OpportunityData {
  sort: string
  order: string
  page: number
  count: number
  fine_tuning: string
  generated_by_model: string
}

export interface OpportunityQueryObjectWithCampaign extends OpportunityQueryObject {
  campaign?: string
  [key: string]: unknown
}

export const urlParamsToOpportunityQueryObject = (params: URLSearchParams): OpportunityQueryObjectWithCampaign => ({
  states: params.getAll("states").map((state) => parseInt(state)),
  cities: params.getAll("cities").map((city) => parseInt(city)),
  counties: params.getAll("counties").map((county) => parseInt(county)),
  organizations: params.getAll("organizations").map((organization) => parseInt(organization)),
  industry: params.getAll("industry"),
  naics_code: params.getAll("naics_code"), // REMOVE THIS ONCE WE REMOVE NAICS CODES OPP SEARCH FEATURE FLAG
  channel_types: params.getAll("channel_types").map((channelType) => parseInt(channelType)),
  city_population: params.getAll("city_population").map((cityPopulation) => parseInt(cityPopulation)) as [
    number,
    number,
  ],
  county_population: params.getAll("county_population").map((countyPopulation) => parseInt(countyPopulation)) as [
    number,
    number,
  ],
  city_income: params.getAll("city_income").map((cityIncome) => parseInt(cityIncome)) as [number, number],
  county_income: params.getAll("county_income").map((countyIncome) => parseInt(countyIncome)) as [number, number],
  terms: params.getAll("terms"),
  sort: params.get("sort") || "",
  order: params.get("order") || "",
  page: parseInt(params.get("page") ?? "0"),
  count: parseInt(params.get("count") ?? "25"),
  fine_tuning: params.get("fine_tuning") ?? "",
  generated_by_model: params.get("generated_by_model") ?? "",
  campaign: params.get("campaign") ?? "",
})

export interface OpportunityIndexResult extends Opportunity {
  naics_code: NaicsCode
  channel_id: number
  channel_name: string
  state_name: string
  county_name?: string
  city_name?: string
  published_at: string
  use_fine_tuning: string
  generated_by_model: string
}

export class OpportunityUrlBuilder extends UrlBuilder {
  collectionParams = ["states", "cities", "counties", "organizations", "industry", "naics_code", "terms"]
  stringParams = ["sort", "order", "generated_by_model"]
  numberParams = [
    { name: "page", defaultValue: 0 },
    { name: "count", defaultValue: 25 },
  ]
  onOffParams = ["fine_tuning"]
}

export const useFetchOpportunities = (params?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()
  const { data: isOpportunitiesEnabled } = useIsOpportunitiesEnabled()

  return useQuery(
    ["opportunities", params && urlParamsToOpportunityQueryObject(params)],
    async () => {
      const url = new OpportunityUrlBuilder(prefix).build(params)
      const response = await (await axiosInstance).get<{ opportunities: OpportunityIndexResult[]; total: number }>(url)

      return response.data
    },
    {
      enabled: Boolean(isOpportunitiesEnabled),
    },
  )
}

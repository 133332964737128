import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  Switch,
  Typography,
  Input,
  Divider,
  Chip,
  Autocomplete,
  TextField,
} from "@mui/material"
import Loading from "../../shared/Loading"
import ShareIcon from "@mui/icons-material/Share"
import { Bookmark, BookmarkType } from "@interfaces/bookmark"
import { industryHiddenMessage, industryVisibleMessage } from "../../Bookmarks/config"
import { useCreateIndustryBookmarkFolder, useUpdateIndustryBookmarkFolder } from "@api/admin/bookmarks"
import { useToast } from "../../shared/ToastHook"
import { AdminFetchedIndustry, FetchedIndustry, FetchedIndustryBookmarkFolder } from "@interfaces/industry"
import { useFetchAllUserBookmarks, useFetchSharedBookmarks } from "@api/bookmarks"
import { useCloseModalKey, useData, useModalKey } from "@src/components/shared/OpenModalHook"

export enum FormTypes {
  CREATE,
  EDIT,
  CONVERT,
  REVERT,
}

export interface FolderFormComponentProps {
  industry: FetchedIndustry
  formType: FormTypes
  activeFolder?: FetchedIndustryBookmarkFolder
  setFormType: Dispatch<SetStateAction<FormTypes | undefined>>
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const FolderFormComponent: FC<FolderFormComponentProps> = ({
  industry,
  formType,
  activeFolder,
  setFormType,
  setIsLoading,
}) => {
  const { data: sharedBookmarks, isLoading: sharedBookmarksLoading } = useFetchSharedBookmarks()
  const { data: personalBookmarks, isLoading: personalBookmarksLoading } = useFetchAllUserBookmarks()
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([])
  const [fetchingBookmarks, setFetchingBookmarks] = useState(true)

  const handleSuccess = (msg: string) => {
    setToast(msg)
  }

  const handleError = (msg: string) => {
    setToast(msg, undefined, undefined, "error")
  }

  const handleSettled = () => {
    setIsLoading(false)
    setFormType(undefined)
  }

  const { mutate: createIndustryBookmark, isLoading: createIndustryIsLoading } = useCreateIndustryBookmarkFolder(
    handleSuccess,
    handleError,
    handleSettled,
  )
  const { mutate: updateIndustryBookmark, isLoading: updateIndustryIsLoading } = useUpdateIndustryBookmarkFolder(
    handleSuccess,
    handleError,
    handleSettled,
  )

  useEffect(() => {
    setIsLoading(createIndustryIsLoading || updateIndustryIsLoading)
  }, [createIndustryIsLoading, updateIndustryIsLoading])

  useEffect(() => {
    if (sharedBookmarks && personalBookmarks) {
      // Sort bookmarks by title
      // get unique bookmarks by id and sort them by title
      const uniqueBookmarks = Array.from(
        new Map([...sharedBookmarks, ...personalBookmarks].map((bookmark) => [bookmark.id, bookmark])).values(),
      )
      const sortedBookmarks = uniqueBookmarks.sort((a, b) => {
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        return 0
      })
      setBookmarks(sortedBookmarks)
    }
  }, [Boolean(sharedBookmarks), Boolean(personalBookmarks)])

  useEffect(() => {
    if (!sharedBookmarksLoading && !personalBookmarksLoading) {
      setFetchingBookmarks(false)
    }
  }, [sharedBookmarksLoading, personalBookmarksLoading])

  const initialFolderState = {
    title: industry.name,
    visible: false,
    id: undefined,
  }

  const [newFolder, setNewFolder] = useState<Partial<Bookmark>>(initialFolderState)

  useEffect(() => {
    if (activeFolder) {
      setNewFolder({
        title: activeFolder.title,
        visible: activeFolder.visible,
        id: activeFolder.id,
      })
    }
  }, [activeFolder])

  const setToast = useToast()

  const createFolder = () => {
    if (newFolder.title === "" || newFolder.title === undefined || newFolder.visible === undefined) {
      console.log("Invalid Folder Data: ", newFolder)
      return
    }
    setIsLoading(true)
    createIndustryBookmark({
      industryId: industry.id,
      title: newFolder.title,
      visible: newFolder.visible,
    })
  }

  const updateFolder = () => {
    if (
      newFolder.title === "" ||
      newFolder.title === undefined ||
      newFolder.visible === undefined ||
      newFolder.id === undefined
    ) {
      console.log("Invalid Folder Data: ", newFolder)
      return
    }
    setIsLoading(true)
    updateIndustryBookmark({
      industryId: industry.id,
      bookmarkFolderId: newFolder.id,
      title: newFolder.title,
      visible: newFolder.visible,
    })
  }

  const convertFolder = (bookmark_type: BookmarkType = BookmarkType.Industry) => {
    if (newFolder.id === undefined) {
      console.log("Invalid Folder Data: ", newFolder)
      return
    }
    // Check that newFolder.id is apart of bookmarks
    if (bookmark_type === BookmarkType.Industry && !bookmarks.find((bookmark) => bookmark.id === newFolder.id)) {
      console.log("Invalid Folder Data: ", newFolder)
      return
    }
    setIsLoading(true)
    updateIndustryBookmark({
      industryId: bookmark_type === BookmarkType.Industry ? industry.id : undefined,
      bookmarkFolderId: newFolder.id,
      bookmark_type: bookmark_type,
    })
  }

  const handleSubmit = () => {
    if (formType === FormTypes.EDIT) {
      updateFolder()
    } else if (formType === FormTypes.CONVERT) {
      convertFolder()
    } else if (formType === FormTypes.REVERT) {
      convertFolder(BookmarkType.Standard)
    } else {
      createFolder()
    }
  }

  const cancelCreateFolder = () => {
    setNewFolder({
      title: "",
      visible: false,
    })
    setFormType(undefined)
  }

  return (
    <Box minWidth={500}>
      {formType === FormTypes.REVERT && (
        <Stack>
          <Typography variant="h6" marginBottom={2}>
            Revert Folder: {newFolder.title}
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            Are you sure you want to revert this folder? This will remove the folder from the industry and make it a
            standard folder. If it is not set to visible, it will only be findable by the bookmark folder creator.
          </Typography>
        </Stack>
      )}
      {formType === FormTypes.CONVERT &&
        (fetchingBookmarks ? (
          <Box padding={2}>
            <Loading useCloverleafIcon />
          </Box>
        ) : (
          <Autocomplete
            fullWidth
            options={bookmarks}
            getOptionLabel={(bookmark) => bookmark.title}
            renderOption={(props, bookmark) => (
              <li {...props}>
                <Stack direction="row" spacing={1} justifyContent={"space-between"} width={"100%"}>
                  {bookmark.title}
                  {bookmark.visible && !bookmark.industry_id && (
                    <Chip sx={{ margin: 1 }} color="primary" icon={<ShareIcon />} label="Public" />
                  )}
                </Stack>
              </li>
            )}
            onChange={(event, newValue) => newValue && setNewFolder({ id: newValue.id })}
            renderInput={(params) => <TextField {...params} label="Select Bookmark" />}
          />
        ))}
      {[FormTypes.CREATE, FormTypes.EDIT].includes(formType) && (
        <>
          <Grid item xs={12} marginBottom={2}>
            <InputLabel>Folder Title</InputLabel>
            <Input
              placeholder="Folder Title"
              value={newFolder.title}
              onChange={(event) => setNewFolder({ ...newFolder, title: event.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Searchable</InputLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={newFolder.visible}
                  onChange={() => setNewFolder({ ...newFolder, visible: !newFolder.visible })}
                />
              }
              label={newFolder.visible ? "Searchable" : "Not Searchable"}
              componentsProps={{ typography: { variant: "body1" } }}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: 500,
                },
              }}
            />
          </Grid>
          <Typography>{newFolder.visible ? industryVisibleMessage : industryHiddenMessage}</Typography>
        </>
      )}
      <Divider sx={{ paddingY: 2, marginBottom: 2 }} />
      <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
        <Button variant="contained" onClick={cancelCreateFolder} sx={{ marginRight: 2 }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {formType === FormTypes.EDIT && "Update Folder"}
          {formType === FormTypes.CONVERT && "Convert Folder"}
          {formType === FormTypes.CREATE && "Create Folder"}
          {formType === FormTypes.REVERT && "Revert Folder"}
        </Button>
      </Grid>
    </Box>
  )
}

export const IndustryBookmarkFolderModalKey = "IndustryBookmarkFolderModal"

export const IndustryBookmarkFolderModal: FC = () => {
  const open = useModalKey(IndustryBookmarkFolderModalKey)
  const close = useCloseModalKey(IndustryBookmarkFolderModalKey)
  const modalData: AdminFetchedIndustry | undefined = useData()
  const [isLoading, setIsLoading] = useState(false)
  const [formType, setFormType] = useState<FormTypes>()
  const [activeFolder, setActiveFolder] = useState<FetchedIndustryBookmarkFolder>()

  const handleCloseDialog = () => {
    setFormType(undefined)
    setActiveFolder(undefined)
    close()
  }

  useEffect(() => {
    if (formType !== FormTypes.EDIT) {
      setActiveFolder(undefined)
    }
  }, [formType])

  return (
    <Box width={"100%"}>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="md">
        <DialogTitle marginTop={2}>Bookmark Folders</DialogTitle>
        <Divider />
        {isLoading || !modalData ? (
          <Box padding={2} minWidth={"500px"}>
            <Loading useCloverleafIcon />
          </Box>
        ) : (
          <DialogContent>
            {formType === undefined && (
              <Grid container marginBottom={1} spacing={2} direction="column">
                <Grid item maxHeight={500} overflow={"auto"}>
                  {!modalData.bookmarks ||
                    (modalData.bookmarks.length === 0 && (
                      <Typography variant="h6" width={{ xs: 400, md: 500 }}>
                        No Folders
                      </Typography>
                    ))}
                  {modalData.bookmarks?.map((folder) => (
                    <Grid item container key={folder.id} alignItems="center">
                      <Box display={"flex"} justifyContent={"flex-start"} alignContent={"center"}>
                        <Typography variant="h6" marginRight={2}>
                          {folder.title}
                        </Typography>
                        <Typography variant="body1">{`(${folder.visible ? "Searchable" : "Not Searchable"})`}</Typography>
                      </Box>
                      <Box flexGrow={1} />
                      <Box marginLeft={2}>
                        <Button
                          onClick={() => {
                            setActiveFolder({
                              title: folder.title,
                              visible: folder.visible,
                              id: folder.id,
                            })
                            setFormType(FormTypes.REVERT)
                          }}
                        >
                          Revert
                        </Button>
                        <Button
                          onClick={() => {
                            setActiveFolder({
                              title: folder.title,
                              visible: folder.visible,
                              id: folder.id,
                            })
                            setFormType(FormTypes.EDIT)
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                <Divider sx={{ paddingY: 1 }} />
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={2}>
                    <Button fullWidth variant="contained" onClick={() => setFormType(FormTypes.CONVERT)}>
                      Convert Folder
                    </Button>
                    <Button fullWidth variant="contained" onClick={() => setFormType(FormTypes.CREATE)}>
                      Create Folder
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {formType !== undefined && (
              <FolderFormComponent
                industry={modalData}
                formType={formType}
                setFormType={setFormType}
                activeFolder={activeFolder}
                setIsLoading={setIsLoading}
              />
            )}
          </DialogContent>
        )}
      </Dialog>
    </Box>
  )
}

import { Video } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { createContext, Dispatch, FC, SetStateAction, useState } from "react"

export enum ContentAcquisitionTabs {
  VIDEOS = 0,
  JOBS = 1,
  DOMAINS = 2,
  PROXIES = 3,
  PROXY_DOMAIN_CONFIGS = 4,
}

// cross resource filtering options
export interface ContentAcquisitionJobsTable {
  tab: ContentAcquisitionTabs
  videoSelected?: Video
}

export const ContentAcquisitionJobsTableContext = createContext<{
  value: ContentAcquisitionJobsTable
  setValue: Dispatch<SetStateAction<ContentAcquisitionJobsTable>>
}>({
  value: {
    tab: ContentAcquisitionTabs.VIDEOS,
    videoSelected: undefined,
  },
  setValue: () => {
    /* placeholder for Typescript */
  },
})

// wrap here for more table contexts
export const Context: FC = ({ children }) => {
  const [value, setValue] = useState<ContentAcquisitionJobsTable>({
    tab: 0,
    videoSelected: undefined,
  })

  return (
    <ContentAcquisitionJobsTableContext.Provider value={{ value, setValue }}>
      {children}
    </ContentAcquisitionJobsTableContext.Provider>
  )
}

import { Button } from "@mui/material"
import { GridRowModes, GridToolbarContainer } from "@mui/x-data-grid"
import AddIcon from "@mui/icons-material/Add"
import { TZDate } from "@date-fns/tz"
import { EditToolbarProps } from "../../DataGrid/editing"

export function AddConfigToTable(props: EditToolbarProps) {
  const { rows, setRows, setRowModesModel } = props

  const handleClick = () => {
    const id = Math.max(...rows.map((row) => row.id)) + 1 // ignored by api

    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        name: "",
        internal_ip: "",
        external_ip: "",
        suspended_until: TZDate.tz("UTC"),
        zone: "",
        type: "squid",
        suspended_globally: false,
        disabled_globally: false,
        environment_type: 0,
        successRate: 0,
        successCount: 0,
        totalCount: 0,
        isNew: true,
      },
    ])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "domain" },
    }))
  }

  return (
    <GridToolbarContainer sx={{ padding: 2 }}>
      <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={handleClick}>
        ADD ROW
      </Button>
    </GridToolbarContainer>
  )
}

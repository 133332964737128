import { FetchedMeeting } from "../api/meetings"
import { RubyObject } from "../interfaces/default"
import { Organization } from "./organization"
import { Transcript } from "./transcript"

export enum ClipType {
  Standard = "standard",
  Promotion = "promotion",
  Industry = "industry",
}

export interface ClipShare extends RubyObject {
  keyword: string
  start_time_in_seconds: string
  description?: string
  title: string
  relevant_text?: string
  transcript_id: number | string
  bookmark_id?: number
  meeting_id?: number
  clip_type?: ClipType
}

export interface ClipShareBookmarkView extends ClipShare {
  organization?: Organization
  organization_id: number
  organization_name: string
  state_name: string
  county_name?: string
  city_name?: string
  meeting: FetchedMeeting
  raw_text: string
  transcript: Transcript
}

export interface ClipShareLeadView extends ClipShare {
  raw_text: string
}

export interface ClipShareWithMethod extends ClipShareBookmarkView {
  shareMethod: any
}

export interface ClipShareAxiosResponse {
  data?: ClipShareBookmarkView
}

interface TranscriptDeleteData {
  raw_text: string
  start_time: number
  duration_seconds: number
}

export interface BaseClipShare extends RubyObject {
  user_id: number
  meeting_id: number
  user_organization_id: number
  transcript_id: number
  keyword: string
  needs_recreate: boolean
  transcript_deleted_data?: TranscriptDeleteData //jsonb
  start_time_in_seconds: number
  title: string
  description: string
  bookmark_id: number
  transcript_doc_id: string
}

export interface UserOrgClipShare {
  id: number
  meeting_title: string
  channel_name: string
  channel_location: string
  user_email: string
  share_path: string
  created_at: string
}

import { FC, useState } from "react"
import { DomainTable } from "./DomainTable"
import { Button, Stack, Typography, Container } from "@mui/material"
import { ProxySuccessByDomain } from "./ProxySuccessByDomain"
import { Domain } from "@src/interfaces/content-acquisition/responseObjectInterfaces"

export const DomainContainer: FC = () => {
  const [selectedDomain, setSelectedDomain] = useState<Domain | undefined>(undefined)

  if (selectedDomain === undefined) {
    return <DomainTable setSelectedRow={setSelectedDomain} />
  } else {
    return (
      <Container maxWidth={false} sx={{ padding: 2 }}>
        <Stack direction="column" spacing={1} margin={2} alignItems="center">
          <Typography variant="h1" fontSize="1.953em">
            {selectedDomain.domain}
          </Typography>
          <Typography variant="h2" fontSize="1.25em">
            Proxy Success Rates, Last 30 Days.
          </Typography>
        </Stack>
        <ProxySuccessByDomain domain={selectedDomain} />
        <Button
          sx={{ marginTop: 1, fontSize: "1.25em", width: "10em" }}
          variant="contained"
          onClick={() => setSelectedDomain(undefined)}
        >
          Back to Domains
        </Button>
      </Container>
    )
  }
}

import { Autocomplete, Box, Skeleton, TextField } from "@mui/material"
import { useGetFeatureFlagByName } from "@src/api/feature_flags"
import { useFetchIndustryIdsMap } from "@src/api/industries"
import { useFetchNaicsCodesMapByCode } from "@src/api/naicsCodes"
import { OpportunitiesContainerContext } from "@src/containers/OpportunitiesContainers/OpportunitiesContainer"
import { FC, useContext } from "react"

export const OpportunityIndustryFilter: FC = () => {
  const { params, urlParamState, setUrlParamState } = useContext(OpportunitiesContainerContext)
  const campaignId = params.get("campaign") ?? ""

  const { data: showNaicsCodesFilter, isLoading: showNaicsCodesFilterLoading } = useGetFeatureFlagByName(
    "OPPORTUNITY_NAICS_CODE_SEARCH_FILTER",
  )
  const { data: naicsCodesMap } = useFetchNaicsCodesMapByCode()
  const { data: industriesDataMap, isLoading: industriesLoading } = useFetchIndustryIdsMap(false, true)

  if (showNaicsCodesFilterLoading) {
    return <Skeleton variant="rectangular" width="100%" height={56} />
  } else if (showNaicsCodesFilter === undefined || showNaicsCodesFilter.value === "true") {
    return (
      <Autocomplete
        multiple
        value={urlParamState.naics_code}
        onChange={(_, newValue) =>
          setUrlParamState({
            ...urlParamState,
            naics_code: newValue.map((value) => value.toString()),
            page: 0,
            campaign: campaignId,
          })
        }
        getOptionLabel={(option) => {
          const naicsCode = naicsCodesMap?.get(option)
          return `${naicsCode?.code}: ${naicsCode?.title}`
        }}
        loading={showNaicsCodesFilterLoading}
        options={naicsCodesMap ? Array.from(naicsCodesMap.keys()) : []}
        renderInput={(params) => <TextField {...params} label="NAICS Code" />}
        renderOption={(props, option) => {
          const naicsCode = naicsCodesMap?.get(option)
          return (
            <Box component="li" {...props} key={option}>
              {`${naicsCode?.code}: ${naicsCode?.title}`}
            </Box>
          )
        }}
      />
    )
  }

  return (
    <Autocomplete
      multiple
      value={urlParamState.industry}
      onChange={(_, newValue) =>
        setUrlParamState({
          ...urlParamState,
          industry: newValue.map((value) => value.toString()),
          page: 0,
          campaign: campaignId,
        })
      }
      getOptionLabel={(option) => {
        const industry = industriesDataMap?.get(option)
        return industry?.name || ""
      }}
      loading={industriesLoading}
      options={industriesDataMap ? Array.from(industriesDataMap.keys()) : []}
      renderInput={(params) => <TextField {...params} label="Industry" />}
      renderOption={(props, option) => {
        const industry = industriesDataMap?.get(option)
        return (
          <Box component="li" {...props} key={option}>
            {industry?.name}
          </Box>
        )
      }}
    />
  )
}

import { FC, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { Button, Divider, Typography, Tab, Tabs } from "@mui/material"
import { NewPermissionContainer } from "./NewPermissionContainer"
import { PermissionsList } from "./PermissionsList"
import { CampaignList } from "../Campaigns/CampaignList"
import { OpportunityCampaigns } from "./OpportunityCampaigns"
import { MetricsContainer } from "../../Home/Metrics/MetricsContainer"
import { Users } from "./UsersSection"
import { useFetchUserOrganization } from "../../../api/admin/user_organizations"
import { AugmentedStatus } from "../../shared/LoadingEllipsis"
import { UserOrganizationPageHeader } from "./UserOrganizationPageHeader"
import { Page as SearchTerms } from "./SearchTerms/Page"
import { CreditUsages } from "./Credits/CreditUsages"
import useSetUrlParamState from "@components/shared/UseSetUrlParamState"
import { BookmarksAdminContainer } from "@src/containers/BookmarksAdminContainer"
import { SharesAdminContainer } from "@src/containers/SharesAdminContainer"

export enum UserOrganizationTabs {
  Users = "Users",
  Campaigns = "Campaigns",
  OpportunityCampaigns = "Opportunity Campaigns",
  SearchTerms = "Search Terms",
  Metrics = "Metrics",
  Permissions = "Permissions",
  Credits = "Credits",
  Shares = "Shares",
  Bookmarks = "Bookmarks",
}

const tabLabels: Record<UserOrganizationTabs, string> = {
  [UserOrganizationTabs.Users]: "Users",
  [UserOrganizationTabs.Campaigns]: "Campaigns",
  [UserOrganizationTabs.OpportunityCampaigns]: "Opportunity Campaigns",
  [UserOrganizationTabs.SearchTerms]: "Search Terms",
  [UserOrganizationTabs.Metrics]: "Metrics",
  [UserOrganizationTabs.Permissions]: "Permissions",
  [UserOrganizationTabs.Credits]: "Credits",
  [UserOrganizationTabs.Shares]: "Shares",
  [UserOrganizationTabs.Bookmarks]: "Bookmarks",
}

export type UserOrganizationPageParams = {
  tab?: UserOrganizationTabs
}

export const UserOrganizationPage: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: userOrganization, status } = useFetchUserOrganization(userOrganizationId)
  const [openPermissions, setOpenPermissions] = useState(false)

  const setFilterParams = useSetUrlParamState<UserOrganizationPageParams>()
  const filterParams = new URLSearchParams(useLocation().search)

  const getTabFromUrl = (tab: string | null): UserOrganizationTabs => {
    if (tab && Object.values(UserOrganizationTabs).includes(tab as UserOrganizationTabs)) {
      return tab as UserOrganizationTabs
    }
    return UserOrganizationTabs.Users
  }

  const [tab, setTab] = useState<UserOrganizationTabs>(getTabFromUrl(filterParams.get("tab")))

  const handleTabChange = (event: React.SyntheticEvent, newValue: UserOrganizationTabs) => {
    setFilterParams({ tab: newValue })
  }

  if (!userOrganization) {
    return (
      <Typography>
        <AugmentedStatus status={status} />
      </Typography>
    )
  }

  return (
    <>
      <UserOrganizationPageHeader />
      <Tabs value={tab} onChange={handleTabChange}>
        {Object.values(UserOrganizationTabs).map((tabValue) => (
          <Tab key={tabValue} label={tabLabels[tabValue]} value={tabValue} />
        ))}
      </Tabs>
      <Divider sx={{ marginBottom: 1 }} />
      {tab == UserOrganizationTabs.Users && <Users />}
      {tab == UserOrganizationTabs.Campaigns && <CampaignList userOrganizationId={userOrganization.id} />}
      {tab == UserOrganizationTabs.OpportunityCampaigns && <OpportunityCampaigns />}
      {tab == UserOrganizationTabs.SearchTerms && <SearchTerms userOrgId={userOrganization.id} />}
      {tab == UserOrganizationTabs.Metrics && (
        <MetricsContainer userOrgId={userOrganization.id} containerMarginTop={5} />
      )}
      {tab == UserOrganizationTabs.Permissions && (
        <>
          <Button onClick={() => setOpenPermissions(true)} size="small" variant="contained">
            Add Organization Permission
          </Button>
          <PermissionsList />
        </>
      )}
      {tab == UserOrganizationTabs.Credits && (
        <>
          <CreditUsages />
        </>
      )}
      {tab == UserOrganizationTabs.Shares && <SharesAdminContainer />}
      {tab == UserOrganizationTabs.Bookmarks && <BookmarksAdminContainer />}
      <NewPermissionContainer
        open={openPermissions}
        onClose={() => setOpenPermissions(false)}
        userOrganization={userOrganization}
      />
    </>
  )
}

import { FC } from "react"
import { TextField, Autocomplete } from "@mui/material"
import { useLocation } from "react-router-dom"
import { urlParamsToOpportunityQueryObject, OpportunityQueryObjectWithCampaign } from "@api/opportunities"
import { useFetchOpportunityCampaignsMap } from "@api/opportunityCampaigns"
import useSetUrlParamState from "../../shared/UseSetUrlParamState"
import { SaveCampaignButton } from "./SaveCampaignButton"
import { UpdateCampaignButton } from "./UpdateCampaignButton"

export const CampaignFields: FC = () => {
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)

  const campaignIdString = params.get("campaign")
  const campaignId = campaignIdString ? parseInt(campaignIdString, 10) : null

  const urlParamState = urlParamsToOpportunityQueryObject(params)
  const setUrlParamState = useSetUrlParamState<OpportunityQueryObjectWithCampaign>()

  const { data: opportunityCampaigns, isFetched: isOpportunityCampaignsFetched } = useFetchOpportunityCampaignsMap()

  const handleChange = (newValue: number | null) => {
    let paramObject = {}

    if (newValue === null) {
      paramObject = { page: 0 }
    } else if (newValue && newValue > 0 && isOpportunityCampaignsFetched) {
      paramObject = {
        ...urlParamState,
        ...(opportunityCampaigns?.get(newValue)?.data || {}),
        campaign: `${newValue}`,
        page: 0,
      }
    } else if (newValue && newValue > 0) {
      paramObject = {
        ...urlParamState,
        ...(opportunityCampaigns?.get(newValue)?.data || {}),
        campaign: `${newValue}`,
        page: 0,
      }
    }

    setUrlParamState(paramObject as OpportunityQueryObjectWithCampaign)
  }

  return (
    <>
      <Autocomplete
        sx={{ flex: "1" }}
        options={opportunityCampaigns ? Array.from(opportunityCampaigns.keys()) : []}
        value={campaignId}
        onChange={(_, newValue) => handleChange(newValue)}
        renderInput={(params) => <TextField {...params} label="Opportunity Campaigns" />}
        getOptionLabel={(id) => opportunityCampaigns?.get(id)?.name || ""}
      />
      <UpdateCampaignButton />
      <SaveCampaignButton />
    </>
  )
}

import { Domain } from "@interfaces/content-acquisition/responseObjectInterfaces"
import { GridColDef } from "@mui/x-data-grid"

export const jobColumns: (domains?: Domain[]) => GridColDef[] = (domains) => [
  { field: "id", headerName: "ID", flex: 0.25, type: "number" },
  { field: "video_id", headerName: "Video ID", flex: 1 },
  { field: "proxy_id", headerName: "Proxy ID", flex: 1 },
  { field: "in_progress", headerName: "In Progress", flex: 1 },
  { field: "success", headerName: "Success", flex: 1, type: "boolean" },
  {
    field: "domain_id",
    headerName: "Domain",
    flex: 1,
    type: "string",
    valueGetter: (value) => {
      const domain = domains?.find((domain) => domain.id === value)
      return domain?.domain || value
    },
  },
  {
    field: "started_at",
    headerName: "Started",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    flex: 1,
  },
  {
    field: "finished_at",
    headerName: "Finished",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    flex: 1,
  },
  { field: "error", headerName: "Error Message", flex: 1 },
  { field: "execution_id", headerName: "Execution ID", flex: 1 },
  {
    field: "created_at",
    headerName: "Created",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    flex: 1,
  },
  {
    field: "updated_at",
    headerName: "Updated At",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    flex: 1,
  },
]

import { useFetchGeographies } from "@api/geographies"
import { FilterParams } from "@src/components/Home/interface"
import { GeographySelectorMultiple } from "@src/components/shared/Selectors/Multiple/Geographies"
import { OpportunitiesContainerContext } from "@src/containers/OpportunitiesContainers/OpportunitiesContainer"
import { Dispatch, FC, SetStateAction, useContext } from "react"

export const OpportunityGeographiesFilters: FC = () => {
  const { params, urlParamState, setUrlParamState } = useContext(OpportunitiesContainerContext)

  const campaignId = params.get("campaign") ?? ""
  const setUrlParamStateWithPage0 = ((newState: typeof urlParamState) =>
    setUrlParamState({ ...newState, campaign: campaignId, page: 0 })) as Dispatch<SetStateAction<FilterParams>>

  const { data: geographiesData, isLoading: geographiesLoading } = useFetchGeographies()
  const defaultGeographies = {
    cities: [],
    states: [],
    counties: [],
  }

  return (
    <>
      <GeographySelectorMultiple
        options={geographiesData || defaultGeographies}
        label="State"
        type="states"
        loading={geographiesLoading}
        filterParams={urlParamState as FilterParams}
        setFilterParams={setUrlParamStateWithPage0}
        sx={{ flex: "20ch 1 0" }}
      />
      <GeographySelectorMultiple
        options={geographiesData || defaultGeographies}
        label="County"
        type="counties"
        loading={geographiesLoading}
        filterParams={urlParamState as FilterParams}
        setFilterParams={setUrlParamStateWithPage0}
        sx={{ flex: "20ch 1 0" }}
      />
      <GeographySelectorMultiple
        options={geographiesData || defaultGeographies}
        label="City"
        type="cities"
        filterParams={urlParamState as FilterParams}
        loading={geographiesLoading}
        setFilterParams={setUrlParamStateWithPage0}
        sx={{ flex: "30ch 1 0" }}
      />
    </>
  )
}

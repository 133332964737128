import { FC, useContext } from "react"
import { JobTable } from "./JobTable"
import { Button, Typography, Container } from "@mui/material"
import { ContentAcquisitionJobsTableContext } from "../Context"
import { unpackSetters } from "@src/components/shared/unpackSetters"

export const JobsContainer: FC = () => {
  const { value, setValue } = useContext(ContentAcquisitionJobsTableContext)
  const { videoSelected } = value
  const { setVideoSelected } = unpackSetters(value, setValue)

  if (videoSelected && setVideoSelected) {
    return (
      <Container maxWidth={false} sx={{ padding: 2 }}>
        <Typography variant="h1" fontSize="1.953em" noWrap>
          {videoSelected.url}
        </Typography>
        <JobTable videoSelected={videoSelected} />
        <Button
          sx={{ marginTop: 1, fontSize: "1.25em", width: "10em" }}
          variant="contained"
          onClick={() => setVideoSelected(undefined)}
        >
          All Jobs
        </Button>
      </Container>
    )
  } else {
    return <JobTable />
  }
}

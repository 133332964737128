import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { useSmartUpdate } from "../api"
import { UserOrganization, DetailedUserOrganization, CreateUserOrganization } from "@interfaces/userOrganization"
import { CreateUserData, User } from "@interfaces/user"
import { BaseCampaign, Campaign, EmailCadence } from "@interfaces/campaign"
import { RubyObject } from "@interfaces/default"

const prefix = "/admin/user_organizations"

export interface CreditUsagesResponse {
  total_records: number
  credit_usages: CreditUsageResponse[]
}

export interface CreditUsageResponse extends RubyObject {
  briefing_id: number
  email: string
  title: string
  url: string
  credit_amount: number
  created_at: string
}

export interface FetchedAdminUserCampaignResponse {
  campaigns: FetchedCampaign[]
  total: number
}

export interface FetchedCampaign extends BaseCampaign {
  assignees: User[]
  creator_notification: EmailCadence
  automated_general_briefings: boolean
  subscriptions: User[]
  user: User
  briefings: number[]
}

export const useFetchUserOrganizations = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations"], async () => {
    const response = await (await axiosInstance).get<UserOrganization[]>(prefix)

    return response.data
  })
}

export const useFetchUserOrganization = (id: number | string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", { id: id.toString() }], async () => {
    const response = await (await axiosInstance).get<DetailedUserOrganization>(`${prefix}/${id}`)

    return response.data
  })
}

export const useFetchUserOrganizationUsers = (id: number | string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["users", { user_organization_id: id.toString() }], async () => {
    const response = await (await axiosInstance).get<User[]>(`${prefix}/${id}/users`)

    return response.data
  })
}

export const useFetchUserOrganizationUsersMapById = (id: unknown) => {
  const fetchData = useFetchUserOrganizationUsers(`${id}`)
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((user) => [user.id, user])),
    }
  }
  return fetchData
}

export const useFetchCampaigns = (id: number, filterParams?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()

  const apiParams = new URLSearchParams({
    name: filterParams?.get("name") || "",
    page: filterParams?.get("page") || "0",
    count: filterParams?.get("count") || "25",
    sort_by: filterParams?.get("sort_by") || "id",
    sort_order: filterParams?.get("sort_order") || "asc",
  })

  if (apiParams.get("count") === "-1") {
    // remove pagination params if count is -1
    apiParams.delete("count")
    apiParams.delete("page")
  }

  return useQuery(
    [
      "campaigns",
      {
        user_organization_id: id.toString(),
        name: apiParams?.get("name"),
        page: apiParams?.get("page"),
        count: apiParams?.get("count"),
        sort_by: apiParams?.get("sort_by"),
        sort_order: apiParams?.get("sort_order"),
      },
    ],
    async () => {
      const response = await (
        await axiosInstance
      ).get<FetchedAdminUserCampaignResponse>(`${prefix}/${id}/campaigns?${apiParams?.toString()}`)

      return response.data
    },
  )
}

export const useCreateUserOrganization = (onSuccess: () => void, onError: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: Partial<CreateUserOrganization>) => {
      return (await axiosInstance).post(prefix, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user_organizations")
        onSuccess()
      },
      onError: () => {
        onError()
      },
    },
  )
}

export const useArchiveOrganization = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  const smartUpdate = useSmartUpdate(useQueryClient())

  return useMutation(
    async (orgId: string) => {
      return (await axiosInstance).delete(`${prefix}/${orgId}`)
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useCreateCampaign = (id: number | string, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()
  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post<Campaign>(`${prefix}/${id}/campaigns`, {
        name: params.name,
        filterParams: params.filterParams,
        searchTerms: params.searchTerms,
        user_id: params.user_id,
        email_cadence: params.emailCadence,
        proximity: params.proximity,
        mustIncludeTerms: params.mustIncludeInput,
        exclude: params.exclude,
        briefings: params.briefings,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns")
        queryClient.invalidateQueries("my_campaigns")
        queryClient.invalidateQueries("my_subscriptions")
        queryClient.invalidateQueries("admin_campaigns")
        queryClient.invalidateQueries("user_organizations")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUpdateUserOrganization = (id: number | string, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const smartUpdate = useSmartUpdate(useQueryClient())

  return useMutation(
    async (params: {
      name: string
      industry_id: number | null
      size: number | null
      salesforce_enabled: boolean
      briefings_enabled: boolean
      speaker_identification_enabled: boolean
      opportunities_enabled: boolean
    }) => {
      return (await axiosInstance).put(`${prefix}/${id}`, params)
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUpdateUserOrganizationLogo = (id: number | string, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const smartUpdate = useSmartUpdate(useQueryClient())

  return useMutation(
    async (logo: File) => {
      return (await axiosInstance).put(`${prefix}/${id}`, logo, {
        headers: {
          "content-type": logo.type,
        },
      })
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useAddUserToUserOrganization = (
  userOrganizationId: number | string,
  onSuccess?: (data?: User) => void,
) => {
  const axiosInstance = useAuthAxios()
  const smartUpdate = useSmartUpdate(useQueryClient())

  return useMutation(
    async (params: CreateUserData) => {
      return (await axiosInstance).post<User>(`${prefix}/${userOrganizationId}/users`, params)
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        if (onSuccess) {
          onSuccess(response.data)
        }
      },
    },
  )
}

export interface AddUsersToOrganizationParams {
  email: string
  requires_signals_notifications: boolean
  role_ids?: number[]
}

export const useAddUsersToUserOrganization = (
  userOrganizationId: number | string,
  onSuccess?: (data?: { id: number; email: string }[]) => void,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (userArray: AddUsersToOrganizationParams[]) => {
      return (await axiosInstance).post<{ id: number; email: string }[]>(`${prefix}/${userOrganizationId}/add_users`, {
        users: userArray,
      })
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user_organizations"])
        queryClient.invalidateQueries(["user_organizations", userOrganizationId, "users"])
        if (onSuccess) {
          onSuccess(data?.data)
        }
      },
    },
  )
}

export const useUpdateCredits = (
  userOrganizationId: number | string,
  onSuccess?: (data?: DetailedUserOrganization) => void,
) => {
  const axiosInstance = useAuthAxios()
  const smartUpdate = useSmartUpdate(useQueryClient())

  return useMutation(
    async (params: { total_credits: number }) => {
      return (await axiosInstance).put<DetailedUserOrganization>(
        `${prefix}/${userOrganizationId}/update_credits`,
        params,
      )
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        if (onSuccess) {
          onSuccess(response.data)
        }
      },
    },
  )
}

export const useFetchUserOrganizationCreditUsages = (id: number | string, page: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["user_organizations", id, "credit_usages", page],
    async () => {
      const response = await (
        await axiosInstance
      ).get<CreditUsagesResponse>(`${prefix}/${id}/credit_usages?page=${page}`)

      return response.data
    },
    {
      enabled: id !== undefined && page > 0,
    },
  )
}

export const useFetchUserOrganizationnTotalCreditsUsed = (id: number | string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", id, "total_credits_used"], async () => {
    const response = await (await axiosInstance).get<number>(`${prefix}/${id}/total_credits_used`)

    return response.data
  })
}

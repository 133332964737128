import React, { FC, SetStateAction, createContext, useState } from "react"
import { Button, ListItem } from "@mui/material"
import { FetchedCampaign } from "@api/admin/user_organizations"
import { SubscribersDialog } from "./SubscribersDialog/SubscribersDialog"
import Loading from "@components/shared/Loading"

export const CampaignListItemContext = createContext<{
  subscribersDialogOpen: boolean
  setSubscribersDialogOpen: React.Dispatch<SetStateAction<boolean>>
  campaign: FetchedCampaign
}>({
  subscribersDialogOpen: false,
  setSubscribersDialogOpen: () => {
    /* placeholder for typescript */
  },
  campaign: {} as FetchedCampaign,
})

export const CampaignSubscriberComponent: FC<{ campaign: FetchedCampaign }> = ({ campaign }) => {
  const [subscribersDialogOpen, setSubscribersDialogOpen] = useState(false)

  return (
    <>
      {!campaign ? (
        <Loading useCloverleafIcon />
      ) : (
        <>
          <Button
            onClick={() => setSubscribersDialogOpen(true)}
            sx={campaign?.subscriptions?.length === 0 ? { display: "none" } : {}}
          >
            Subscribers
          </Button>
          {subscribersDialogOpen && (
            <CampaignListItemContext.Provider
              value={{
                subscribersDialogOpen,
                setSubscribersDialogOpen,
                campaign,
              }}
            >
              <SubscribersDialog />
            </CampaignListItemContext.Provider>
          )}
        </>
      )}
    </>
  )
}

import { GridColDef } from "@mui/x-data-grid"

export const videoColumns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 0.25, type: "number" },
  { field: "url", headerName: "URL", flex: 1, type: "string" },
  { field: "checksum", headerName: "Checksum", flex: 1 },
  { field: "bucket", headerName: "Bucket", flex: 1 },
  { field: "success", headerName: "Success", flex: 0.5, type: "boolean" },
  { field: "attempts", headerName: "Attempts", flex: 0.5, type: "number" },
  { field: "error", headerName: "Error", flex: 1 },
  { field: "meeting_id", headerName: "Meeting ID", flex: 0.5 },
  {
    field: "transcription_job_id",
    headerName: "Tj ID",
    flex: 0.25,
  },
  { field: "media_type", headerName: "Media Type", flex: 0.5 },
  { field: "storage_type", headerName: "Storage Type", flex: 0.5 },
  { field: "proxy_type", headerName: "Proxy Type", flex: 0.5 },
  {
    field: "created_at",
    headerName: "Created",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
    type: "dateTime",
    description: "The time the video was sent to Bombus",
    flex: 1,
  },
]

import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../../axiosInstance"
import { Domain } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { QueryParamsBuilder } from "@src/interfaces/content-acquisition/queryParamsBuilder"

const resourcePath = "/content-acquisition/domains"

export const useFetchDomains = (params?: CommonUrlParams) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}${queryParams}`

  return useQuery(paramBuilder.toQueryKey("content-acquistion-domains"), async () => {
    const response = await (await axiosInstance).get<Domain[]>(uri)

    return response.data
  })
}

// do not copy paste this method, it bases the request body off of a response object, define the request body instead
export const useCreateDomain = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: Partial<Domain>) => {
      const response = await (await axiosInstance).post<Domain>(resourcePath, requestBody)
      return response.data
    },
    {
      onSuccess: (_response) => {
        queryClient.invalidateQueries(["content-acquistion-domains"])
      },
    },
  )
}

// do not copy paste this method, it bases the request body off of a response object, define the request body instead
export const useUpdateDomain = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (requestBody: Partial<Domain>) => {
      const response = await (await axiosInstance).put<Domain>(`${resourcePath}/${requestBody.id}`, requestBody)
      return response.data
    },
    {
      onSuccess: (_response) => {
        queryClient.invalidateQueries(["content-acquistion-domains"])
      },
    },
  )
}

export const useDeleteDomain = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: number) => {
      const response = await (await axiosInstance).delete<Domain>(`${resourcePath}/${id}`)
      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["content-acquistion-domains"])
      },
    },
  )
}

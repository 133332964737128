import { UserOrgClipShare } from "@interfaces/clipShare"

const dateStringToLocaleDate = (dateString: string) => new Date(dateString).toLocaleDateString()

export const userOrgSharesRow = (clip: UserOrgClipShare) => {
  return {
    id: clip.id,
    channel_name: clip.channel_name,
    channel_location: clip.channel_location,
    created_at: dateStringToLocaleDate(clip.created_at),
    meeting_title: clip.meeting_title,
    share_path: clip.share_path,
    user_email: clip.user_email,
  }
}

import { FC, useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
  Typography,
  List,
  ListItem,
  Box,
  FormControlLabel,
  Switch,
  Skeleton,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { AdvancedSearch, AdvancedSearchTypes } from "../../../shared/AdvancedSearch"
import { geographySelectorConfig } from "../../../shared/config"
import { GeographySelectorMultiple } from "../../../shared/Selectors/Multiple/Geographies"
import { ChannelSelectorMultiple } from "../../../shared/Selectors/Multiple/Channel"
import { useUpdateCampaign } from "../../../../api/admin/campaigns"
import { useFetchGeographies } from "../../../../api/geographies"
import { useFetchOrganizations } from "@src/api/admin/organizations"
import { FetchedCampaign, useFetchUserOrganizationUsers } from "../../../../api/admin/user_organizations"
import { useCreateCampaign } from "../../../../api/admin/user_organizations"
import { EmailCadence } from "../../../../interfaces/campaign"
import { useToast } from "../../../shared/ToastHook"
import { BasicFilterParams } from "../../../shared/Selectors/interface"
import { User } from "../../../../interfaces/user"
import { TypeSelectorMultiple } from "../../../shared/Selectors/Multiple/Types"
import { FilterParams } from "../../../Home/interface"
import { RangeSlider } from "../../../shared/Selectors/Multiple/Range"
import { EnableGeneralBriefing } from "../../../shared/EnableGeneralBriefing"
import { CampaignContext } from "../../../Campaigns/CampaignListItem"
import { tranformFetchedToCampaign } from "../../../../functions/campaigns"
import { PersonalBriefingPromptSelector } from "../../../shared/PersonalBriefingPromptSelector"
import { useFetchEstimateGeneralBriefingCost } from "../../../../api/campaigns"
import { WarningDialog } from "../../../shared/EnableGeneralBriefing/WarningDialog"
import { useOpenModalKey } from "../../../shared/OpenModalHook"
import { City, County, State } from "@interfaces/geography"

export const CampaignEditButton: FC<{
  initialData?: FetchedCampaign
  variant?: "contained" | "text" | "outlined"
}> = ({ children, initialData, variant }) => {
  const setToast = useToast()
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()

  const [showCampaignDialog, setShowCampaignDialog] = useState(false)
  const { data: geographiesData } = useFetchGeographies(userOrganizationId)
  const { data: usersData } = useFetchUserOrganizationUsers(userOrganizationId)
  const { data: organizationsData } = useFetchOrganizations({
    userOrganizationId: userOrganizationId,
    scope: "user-organizations",
    extend: "simple",
  })
  const { data: estimatedCost } = useFetchEstimateGeneralBriefingCost(initialData?.id, showCampaignDialog)

  const [nameInput, setNameInput] = useState(initialData?.name || "")
  const [mustIncludeInput, setMustIncludeInput] = useState<string[]>(initialData?.data?.mustIncludeTerms || [])
  const [filterTermsInput, setFilterTermsInput] = useState<string[]>(initialData?.data?.searchTerms || [])
  const [locationParams, setLocationParams] = useState<FilterParams>(
    initialData?.data?.filterParams || {
      states: [],
      cities: [],
      counties: [],
      organizations: [],
      channel_types: [],
      city_population: [-1, -1],
      county_population: [-1, -1],
      city_income: [-1, -1],
      county_income: [-1, -1],
    },
  )

  const [emailCadence, setEmailCadence] = useState<"Unsubscribed" | "Weekly" | "Daily">(
    initialData?.creator_notification || "Unsubscribed",
  )
  const [userId, setUserId] = useState(initialData?.user_id || "")
  const [proximity, setProximity] = useState<number | null>(initialData?.data?.proximity || null)
  const [exclude, setExclude] = useState(initialData?.data?.exclude || false)
  const [generalBriefing, setGeneralBriefing] = useState(initialData?.automated_general_briefings || false)
  const [showBriefingSelector, setShowBriefingSelector] = useState(
    Boolean(initialData?.briefings && initialData.briefings.length),
  )
  const [briefings, setBriefings] = useState<Set<number>>(new Set(initialData?.briefings || []))
  const personalBriefingWarningModalKey = `PERSONAL_BRIEFING_WARNING_MODAL_KEY_${initialData?.id}`
  const openWarning = useOpenModalKey(personalBriefingWarningModalKey)

  useEffect(() => {
    if (initialData?.data?.filterParams) {
      setLocationParams(initialData?.data?.filterParams)
    }
  }, [initialData])

  const reset = () => {
    setShowCampaignDialog(false)
    setNameInput(initialData?.name || "")
    setMustIncludeInput(initialData?.data?.mustIncludeTerms || [])
    setFilterTermsInput(initialData?.data?.searchTerms || [])
    setLocationParams(
      initialData?.data?.filterParams || {
        states: [],
        cities: [],
        counties: [],
        organizations: [],
        channel_types: [],
        city_population: [-1, -1],
        county_population: [-1, -1],
        city_income: [-1, -1],
        county_income: [-1, -1],
      },
    )
    setEmailCadence((initialData?.creator_notification as "Unsubscribed" | "Weekly" | "Daily") || "Unsubscribed")
    setUserId(initialData?.user_id || "")
    setProximity(initialData?.data?.proximity || null)
    setExclude(initialData?.data?.exclude || false)
  }

  const { mutate: updateCampaign } = useUpdateCampaign(initialData?.id || 0, () => {
    setToast(`Successfully updated ${nameInput}`)
    setShowCampaignDialog(false)
  })
  const { mutate: createCampaign } = useCreateCampaign(userOrganizationId, () => {
    setToast(`Successfully created ${nameInput}`)
    reset()
  })

  const [tagsRef, setTagsRef] = useState(null)
  let geographies: { states: State[]; counties: County[]; cities: City[]; organizations: [] } = {
    states: [],
    counties: [],
    cities: [],
    organizations: [],
  }
  if (geographiesData) {
    geographies = {
      ...geographies,
      states: geographiesData.states,
      counties: geographiesData.counties,
      cities: geographiesData.cities,
    }
  } else {
    geographies = { states: [], counties: [], cities: [], organizations: [] }
  }

  const advanced_search_data: AdvancedSearchTypes = {
    filterParams: locationParams,
    searchTerms: filterTermsInput,
    mustIncludeTerms: mustIncludeInput,
    searchInputTagsRef: tagsRef,
    proximity,
    exclude,
    briefings,
    objectSetters: {
      setFilterParams: setLocationParams,
      setSearchTerms: setFilterTermsInput,
      setMustIncludeTerms: setMustIncludeInput,
      setSearchInputTagsRef: setTagsRef,
      setProximity,
      setExclude,
      setBriefings,
    },
  }

  return (
    <>
      <Button onClick={() => setShowCampaignDialog(true)} variant={variant}>
        {children}
      </Button>
      <Dialog open={showCampaignDialog} onClose={() => setShowCampaignDialog(false)} fullWidth maxWidth="lg">
        <DialogTitle>{children}</DialogTitle>
        <DialogContent sx={{ overflowY: "unset" }}>
          <Stack spacing={1}>
            {!initialData && (
              <FormControl fullWidth>
                <InputLabel id="user-select-label">Users *</InputLabel>
                <Select
                  required
                  value={userId}
                  onChange={(event) => setUserId(event.target.value as number)}
                  labelId="user-select-label"
                  label="User"
                >
                  {usersData?.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user?.email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              required
              value={nameInput}
              onChange={(event) => setNameInput(event.target.value)}
              label="Campaign Name"
              fullWidth
            />
            <AdvancedSearch data={advanced_search_data} />
            {initialData && (
              <>
                <Stack direction="column" spacing={1}>
                  {geographySelectorConfig.map((geographySelector) => (
                    <GeographySelectorMultiple
                      key={geographySelector.type}
                      options={geographies as BasicFilterParams}
                      label={geographySelector.label}
                      type={geographySelector.type}
                      filterParams={locationParams}
                      setFilterParams={setLocationParams}
                      size="small"
                    />
                  ))}
                  <TypeSelectorMultiple
                    filterParams={locationParams}
                    setFilterParams={setLocationParams}
                    size="small"
                  />
                </Stack>
                <ChannelSelectorMultiple
                  filterParams={locationParams}
                  setFilterParams={setLocationParams}
                  options={organizationsData?.organizations}
                  size="small"
                />
              </>
            )}
            <Stack direction="row" justifyContent="space-between" width="100%">
              <RangeSlider
                filterParams={locationParams}
                setFilterParams={setLocationParams}
                filterType="city_population"
                label="City Population"
              />
              <RangeSlider
                filterParams={locationParams}
                setFilterParams={setLocationParams}
                filterType="county_population"
                label="County Population"
              />
              <RangeSlider
                filterParams={locationParams}
                setFilterParams={setLocationParams}
                filterType="city_income"
                label="City Household Income"
              />
              <RangeSlider
                filterParams={locationParams}
                setFilterParams={setLocationParams}
                filterType="county_income"
                label="County Household Income"
              />
            </Stack>
            <Box paddingY={4}>
              <FormControl fullWidth>
                <InputLabel id="notification-cadence-admin">Notify Campaign Owner By Email</InputLabel>
                <Select
                  labelId="notification-cadence"
                  id="notification-cadence-admin"
                  value={emailCadence}
                  label="Notify By Email"
                  onChange={(e) => setEmailCadence(e.target.value as EmailCadence)}
                >
                  <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {initialData && (
              <>
                <Stack direction="row">
                  <CampaignContext.Provider value={{ campaign: tranformFetchedToCampaign(initialData) }}>
                    <EnableGeneralBriefing
                      generalBriefing={{ briefing: generalBriefing, setBriefing: setGeneralBriefing }}
                    />
                  </CampaignContext.Provider>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showBriefingSelector}
                        onChange={(_event, checked) => {
                          if (checked) {
                            openWarning()
                          } else {
                            setBriefings(new Set())
                            setShowBriefingSelector(false)
                          }
                        }}
                      />
                    }
                    label="Enable Personal Briefings"
                  />
                </Stack>
                <WarningDialog
                  modalKey={personalBriefingWarningModalKey}
                  onCancel={() => setShowBriefingSelector(false)}
                  onSubmit={() => setShowBriefingSelector(true)}
                  personalBriefingWarning
                />
                {showBriefingSelector && (
                  <>
                    <PersonalBriefingPromptSelector selectedPrompts={briefings} setSelectedPrompts={setBriefings} />
                    <Stack direction="row" spacing={1}>
                      <Typography>Estimated Cost:</Typography>
                      <Typography>
                        {estimatedCost ? (briefings.size * estimatedCost.cost).toFixed(2) : <Skeleton width="4ch" />}
                      </Typography>
                    </Stack>
                  </>
                )}
              </>
            )}
            <Typography sx={{ alignSelf: "center" }} variant="h5">
              Subscribed Users
            </Typography>
            <List
              sx={{
                marginLeft: 3,
                display: "flex",
                flexFlow: "column wrap",
                height: 200,
                padding: 0,
                overflow: "auto",
                width: "auto",
              }}
            >
              {initialData?.subscriptions?.map((user: User) => (
                <>
                  <ListItem key={user?.id}>
                    <Typography>{user?.email}</Typography>
                  </ListItem>
                </>
              ))}
            </List>
          </Stack>
          <DialogActions>
            <Button
              onClick={() => {
                if (!initialData) {
                  createCampaign({
                    name: nameInput,
                    filterParams: locationParams,
                    searchTerms: filterTermsInput,
                    user_id: userId,
                    emailCadence: emailCadence,
                    mustIncludeInput: mustIncludeInput,
                    proximity,
                    exclude,
                    briefings: Array.from(briefings),
                  })
                } else {
                  updateCampaign({
                    name: nameInput,
                    filterParams: locationParams,
                    searchTerms: filterTermsInput,
                    emailCadence: emailCadence,
                    mustIncludeInput: mustIncludeInput,
                    proximity,
                    exclude,
                    generalBriefing,
                    briefings: Array.from(briefings),
                  })
                }
              }}
              disabled={filterTermsInput.length === 0 || !nameInput || !userId}
            >
              {initialData ? "Save" : "Create"}
            </Button>
            <Button onClick={() => setShowCampaignDialog(false)}>Cancel</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

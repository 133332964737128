import { FC, useEffect, useState } from "react"
import { IOrganizationRouteParams } from "../../api/interfaces"
import { OrganizationForm } from "../../components/Admin/Organizations/OrganizationForm/OrganizationForm"
import { Button, Checkbox, Divider, Grid, Stack, TextField, Typography } from "@mui/material"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { useParams } from "react-router-dom"
import { useCopyOrganization } from "../../api/organizations"
import { useFetchOrganization } from "../../api/admin/organizations"
import { usePatchOrganization } from "../../api/admin/organizations"
import {
  useGenerateMeetingsForOrg,
  useEnableOppAnalysis,
  useDisableOppAnalysis,
  useEnableSpeakerIdentification,
  useDisableSpeakerIdentification,
} from "../../api/admin/organizations"
import { Confirm } from "../../components/shared/Confirm"
import Loading from "../../components/shared/Loading"
import { useIsSuperAdmin } from "../../api/users"
import { useToast } from "../../components/shared/ToastHook"
import { OrganizationFormWrapper } from "../../components/Admin/Organizations/OrganizationForm/Context"
import { ConfirmationModal } from "../../components/shared/ConfirmationModal"
import { useOpenModalKey } from "../../components/shared/OpenModalHook"
import { useConfirmationModal } from "@src/stories/ConfirmationModal"

const OPP_ANALYSIS_CONFIRMATION_MODAL = "OPP_ANALYSIS_CONFIRMATION_MODAL"

export const InfoSection: FC = () => {
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const { data: organization } = useFetchOrganization(organizationId)
  const { mutate: createMeetings, isLoading: isLoadingGenerate } = useGenerateMeetingsForOrg(organizationId)
  const { mutate: updateOrganization, error: errorUpdateOrganization } = usePatchOrganization()
  const { mutate: copyOrganization } = useCopyOrganization()
  const { mutate: enableOppAnalysis } = useEnableOppAnalysis(organizationId)
  const { mutate: disableOppAnalysis } = useDisableOppAnalysis(organizationId)
  const { mutate: enableSpeakerIdentification } = useEnableSpeakerIdentification(organizationId)
  const { mutate: disableSpeakerIdentification } = useDisableSpeakerIdentification(organizationId)
  const setToast = useToast()

  useEffect(() => {
    if (errorUpdateOrganization && errorUpdateOrganization.response?.data?.customError) {
      setToast(
        "Update failed: " + errorUpdateOrganization.response.data.customError,
        10_000,
        <ErrorOutlineIcon />,
        "error",
      )
    }
  }, [errorUpdateOrganization])

  const [showMeetingDialog, setShowMeetingDialog] = useState(false)
  const [dialogCheckbox, setDialogCheckbox] = useState(false)
  const [showConfirmInactive, setShowConfirmInactive] = useState(false)
  const [startYear, setStartYear] = useState(2000)
  const [endYear, setEndYear] = useState(2022)
  const { data: isSuperAdmin } = useIsSuperAdmin()

  const openModal = useOpenModalKey(OPP_ANALYSIS_CONFIRMATION_MODAL)

  const speakerIdentificationToggle = useConfirmationModal(
    organization?.speaker_identification_enabled ? disableSpeakerIdentification : enableSpeakerIdentification,
    {
      disabled: !organization,
      title: `${organization?.speaker_identification_enabled ? "Disable" : "Enable"} Speaker Identification`,
      body: `Are you sure you want to ${
        organization?.speaker_identification_enabled ? "disable" : "enable"
      } Speaker Identification?`,
    },
  )

  if (!organization) {
    return <Loading />
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} hidden={organization?.archive_status === "active"} padding={4} margin={4}>
          <Typography variant="h3" color="error">
            ***DEACTIVATED CHANNEL***
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <OrganizationFormWrapper organization={organization}>
            <OrganizationForm organization={organization} />
          </OrganizationFormWrapper>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid container item padding={2} direction="row" alignItems="center">
          <Grid item>
            <Button
              onClick={dialogCheckbox ? () => setShowMeetingDialog(true) : createMeetings}
              variant="contained"
              disabled={isLoadingGenerate}
            >
              Create Meetings
            </Button>
          </Grid>
          <Grid item>
            <Checkbox value={dialogCheckbox} onChange={() => setDialogCheckbox(!dialogCheckbox)} />
          </Grid>
          <Grid item>
            <Typography>(Go further back than 1 year)</Typography>
          </Grid>
          {isLoadingGenerate && <Loading />}
        </Grid>
        <Grid container item padding={2} direction="row" alignItems="center">
          <Grid item spacing={2}>
            <Button onClick={() => copyOrganization({ orgId: organization.id })} variant="outlined" color="warning">
              Copy Channel
            </Button>
          </Grid>
        </Grid>
        <Grid container item padding={2} direction="row" alignItems="center">
          <Grid item hidden={showConfirmInactive === false} spacing={2}>
            <Button
              onClick={() => {
                updateOrganization({
                  id: organization.id,
                  archive_status: organization.archive_status === "active" ? "inactive" : "active",
                })
                setShowConfirmInactive(false)
              }}
              variant="outlined"
              color="error"
            >
              Confirm
            </Button>
            <Button onClick={() => setShowConfirmInactive(false)} variant="contained">
              Cancel
            </Button>
          </Grid>
          {isSuperAdmin && (
            <Grid item hidden={showConfirmInactive === true}>
              <Button onClick={() => setShowConfirmInactive(true)} variant="outlined" color="error">
                {organization.archive_status === "active" ? "Deactivate" : "Reactivate"}
                &nbsp; Channel
              </Button>
            </Grid>
          )}
        </Grid>
        <Confirm
          open={showMeetingDialog}
          onClose={() => setShowMeetingDialog(false)}
          onSucceed={() => createMeetings({ startYear, endYear })}
          title="Select a date range"
          renderBody={() => (
            <Grid container marginTop={1}>
              <Grid item xs={6}>
                <TextField
                  value={startYear}
                  onChange={(event: any) => setStartYear(event.target.value)}
                  label="Start Year"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField value={endYear} onChange={(event: any) => setEndYear(event.target.value)} label="End Year" />
              </Grid>
              <Grid item xs={12} marginTop={1}>
                <Typography>(If none is selected, it will default to last year)</Typography>
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          {organization.opp_analysis_enabled ? (
            <>
              <Typography variant="h5">Opportunity Analysis Enabled</Typography>
              <Button variant="contained" onClick={openModal}>
                Disable Opportunity Analysis
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h5">Opportunity Analysis Disabled</Typography>
              <Button variant="contained" onClick={openModal}>
                Enable Opportunity Analysis
              </Button>
            </>
          )}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h5">
            {`Speaker Identification ${organization.speaker_identification_enabled ? "Enabled" : "Disabled"}`}
          </Typography>
          <Button variant="contained" onClick={speakerIdentificationToggle}>
            {`${organization.speaker_identification_enabled ? "Disable" : "Enable"} Speaker Identification`}
          </Button>
        </Stack>
      </Stack>
      <ConfirmationModal
        modalKey={OPP_ANALYSIS_CONFIRMATION_MODAL}
        onConfirm={organization.opp_analysis_enabled ? disableOppAnalysis : enableOppAnalysis}
        title={`${organization.opp_analysis_enabled ? "Disable" : "Enable"} Opportunity Analysis"`}
        body={`Are you sure you want to ${organization.opp_analysis_enabled ? "disable" : "enable"} Opportunity Analysis?`}
      />
    </>
  )
}

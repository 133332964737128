import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"

const ConfirmationModalContext = createContext<{
  setTitle: Dispatch<SetStateAction<ReactNode>>
  setBody: Dispatch<SetStateAction<ReactNode>>
  setDisabled: Dispatch<SetStateAction<boolean>>
  setOnConfirm: Dispatch<SetStateAction<() => void>>
  setOnCancel: Dispatch<SetStateAction<() => void>>
  setIsOpen: Dispatch<SetStateAction<boolean>>
}>({
  setTitle: () => {
    /* placeholder for typescript */
  },
  setBody: () => {
    /* placeholder for typescript */
  },
  setDisabled: () => {
    /* placeholder for typescript */
  },
  setOnConfirm: () => {
    /* placeholder for typescript */
  },
  setOnCancel: () => {
    /* placeholder for typescript */
  },
  setIsOpen: () => {
    /* placeholder for typescript */
  },
})

export const ConfirmationModalProvider: FC = ({ children }) => {
  const [title, setTitle] = useState<ReactNode>(null)
  const [body, setBody] = useState<ReactNode>(null)
  const [disabled, setDisabled] = useState(false)
  const [onConfirm, setOnConfirm] = useState(() => () => {})
  const [onCancel, setOnCancel] = useState(() => () => {})
  const [isOpen, setIsOpen] = useState(false)
  const close = () => {
    setIsOpen(false)
  }

  return (
    <ConfirmationModalContext.Provider
      value={{
        setTitle,
        setBody,
        setDisabled,
        setOnConfirm,
        setOnCancel,
        setIsOpen,
      }}
    >
      <Dialog open={isOpen} onClose={close}>
        {<DialogTitle>{title || "Are you sure?"}</DialogTitle>}
        {body && <DialogContent>{body}</DialogContent>}
        <DialogActions>
          <Button
            onClick={() => {
              onCancel?.()
              close()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onConfirm()
              close()
            }}
            disabled={disabled}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </ConfirmationModalContext.Provider>
  )
}

export const useConfirmationModal = (
  onConfirm: () => void,
  options?: {
    title?: ReactNode
    body?: ReactNode
    disabled?: boolean
    onCancel?: () => void
  },
) => {
  const context = useContext(ConfirmationModalContext)
  if (!context) {
    throw new Error("useConfirmationModal must be used within a ConfirmationModalProvider")
  }

  const { setTitle, setBody, setDisabled, setOnConfirm, setOnCancel, setIsOpen } = context

  return () => {
    setIsOpen(true)
    setTitle(options?.title || "Are you sure?")
    setBody(options?.body || null)
    setDisabled(options?.disabled ?? false)
    setOnConfirm(() => onConfirm)
    setOnCancel(() => options?.onCancel || (() => {}))
  }
}

import { useMutation, useQuery, useQueryClient } from "react-query"
import { ClipShare, UserOrgClipShare } from "@interfaces/clipShare"
import { useAuthAxios } from "../axiosInstance"
import { ClipShareCreateParams } from "../clip_shares"
import { UserOrgBookmarkClip } from "@interfaces/userOrgBookmarkClip"

const prefix = "admin/clip_shares"

export const useCreateClipShare = (onSuccess?: (data: ClipShare) => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (clipShare: ClipShareCreateParams) => {
      const response = await (
        await axiosInstance
      ).post<ClipShare>(prefix, {
        keyword: clipShare.keyword,
        meeting_id: clipShare.meetingId,
        transcript_id: clipShare.transcriptId,
      })
      return response.data
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          queryClient.invalidateQueries(["bookmarks"])
          onSuccess(response)
        }
      },
    },
  )
}

export interface UserOrgDataGridParams {
  page?: number
  sortDir?: string | null
  sortField?: string
  userOrgId: number
}

export interface FetchUserOrgBookmarksResults {
  user_org_bookmark_clips: UserOrgBookmarkClip[]
  total: number
}

export const useFetchUserOrgBookmarkClips = (params: UserOrgDataGridParams) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["admin_bookmark_clips", params],
    async () => {
      const response = await (
        await axiosInstance
      ).get<FetchUserOrgBookmarksResults>(`admin/user_organizations/${params.userOrgId}/clip_shares/bookmark_clips`, {
        params: {
          page: params.page,
          sort_direction: params.sortDir,
          sort_field: params.sortField,
        },
      })

      return response.data
    },
    {
      enabled: params.userOrgId > 0,
    },
  )
}

export interface FetchUserOrgSharedClipsResults {
  user_org_clips: UserOrgClipShare[]
  total: number
}

export const useFetchUserOrgSharedClips = (params: UserOrgDataGridParams) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["admin_shared_clips", params],
    async () => {
      const response = await (
        await axiosInstance
      ).get<FetchUserOrgSharedClipsResults>(`admin/user_organizations/${params.userOrgId}/clip_shares/shared_clips`, {
        params: {
          page: params.page,
          sort_direction: params.sortDir,
          sort_field: params.sortField,
        },
      })
      return response.data
    },
    {
      enabled: params.userOrgId > 0,
    },
  )
}

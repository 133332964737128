import { useDeleteIndustry, useRestoreIndustry, useUpdateIndustry } from "@src/api/admin/industries"
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  gridClasses,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridRowsProp,
  GridSortDirection,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid"
import { FC, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { RecommendedCampaignsModalKey } from "./RecommendedCampaignsModal"
import useSetUrlParamState from "@src/components/shared/UseSetUrlParamState"
import { Box, Button, Paper } from "@mui/material"
import { IndustryBookmarkFolderModalKey } from "./IndustryBookmarkFolderModal"
import { AssociatedUserModalKey } from "./AssociatedUserModal"
import { formatDatetime } from "@src/components/shared/config"
import { useOpenModalKey, useSetData } from "@src/components/shared/OpenModalHook"
import { NAICS_CODES_MODAL_KEY } from "./NaicsCodesModal"
import { AdminFetchedIndustry, AdminEditableIndustryFields } from "@src/interfaces/industry"
import { isEqual } from "lodash"
import { useToast } from "@src/components/shared/ToastHook"

export interface IndustryDataGridProps {
  industries: AdminFetchedIndustry[]
  isLoading: boolean
  totalCount?: number
}

export const IndustryDataGrid: FC<IndustryDataGridProps> = ({ industries, isLoading, totalCount }) => {
  const params = new URLSearchParams(useLocation().search)
  const setToast = useToast()
  const { mutate: restoreIndustry } = useRestoreIndustry(() => setToast("Industry restored successfully"))
  const { mutate: updateIndustry } = useUpdateIndustry(() => setToast("Industry updated successfully"))
  const { mutate: deleteIndustry } = useDeleteIndustry(() => setToast("Industry discarded successfully"))
  const setDataOnModal = useSetData()
  const openModal = useOpenModalKey(NAICS_CODES_MODAL_KEY)

  const openAssociatedUserModal = useOpenModalKey(AssociatedUserModalKey)
  const openIndustyrBookmarkFolderModal = useOpenModalKey(IndustryBookmarkFolderModalKey)
  const openRecommendedCampaignsModal = useOpenModalKey(RecommendedCampaignsModalKey)

  const filterParams = useMemo(
    () => ({
      name: params.get("name") || undefined,
      page: parseInt(params.get("page") || "0"),
      count: parseInt(params.get("count") || "25"),
      title: params.get("title") || undefined,
      organization: params.get("organization") || undefined,
      sort_by: params.get("sort_by"),
      sort_order: params.get("sort_order"),
    }),
    [params],
  )

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: filterParams.count,
    page: filterParams.page,
  })

  const [sortingModel, setSortingModel] = useState<GridSortModel>(
    filterParams.sort_by
      ? [
          {
            field: filterParams.sort_by,
            sort: filterParams.sort_order ? (filterParams.sort_order as GridSortDirection) : "asc",
          },
        ]
      : [],
  )

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    discarded_at: false,
  })

  const handleOpenNaicsCodesModal = (industry: AdminFetchedIndustry) => {
    setDataOnModal({ industry })
    openModal()
  }

  const handleOpenAssociatedUserModal = (industry: AdminFetchedIndustry) => {
    setDataOnModal(industry)
    openAssociatedUserModal()
  }

  const handleOpenIndustryBookmarkFolderModal = (industry: AdminFetchedIndustry) => {
    setDataOnModal(industry)
    openIndustyrBookmarkFolderModal()
  }

  const handleOpenRecommendedCampaignsModal = (industry: AdminFetchedIndustry) => {
    setDataOnModal(industry)
    openRecommendedCampaignsModal()
  }

  const hasChanges = (industry: AdminFetchedIndustry) => {
    const originalIndustryRow = industries.find((row) => row.id === industry.id)
    if (!originalIndustryRow) return false

    const editable_fields = columns.filter((column) => column.editable).map((column) => column.field)

    const obj1 = Object.fromEntries(
      Object.entries(originalIndustryRow).filter(([key]) => editable_fields.includes(key)),
    )
    const obj2 = Object.fromEntries(Object.entries(industry).filter(([key]) => editable_fields.includes(key)))
    return !isEqual(obj1, obj2)
  }

  const handleUpdateIndustry = (row: GridValidRowModel) => {
    const originalIndustryRow = industries.find((industry) => industry.id === row.id)
    if (!originalIndustryRow) {
      console.error("Original industry row not found")
      return
    }
    const editable_fields = columns.filter((column) => column.editable).map((column) => column.field)
    // Get only the fields that have changed
    const updatedFields: Partial<AdminEditableIndustryFields> = Object.fromEntries(
      Object.entries(row).filter(
        ([key]) => editable_fields.includes(key) && row[key] !== originalIndustryRow[key as keyof AdminFetchedIndustry],
      ),
    )
    updateIndustry({ id: row.id as number, ...updatedFields })
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 50 },
    { field: "name", headerName: "Name", flex: 3, minWidth: 200, editable: true },
    { field: "discarded_at", headerName: "Discarded At", flex: 2, minWidth: 150 },
    { field: "created_at", headerName: "Created At", flex: 2, minWidth: 150 },
    { field: "updated_at", headerName: "Updated At", flex: 2, minWidth: 150 },
    {
      field: "show_for_onboarding",
      headerName: "Active for Onboarding",
      flex: 1,
      minWidth: 150,
      type: "boolean",
      editable: true,
    },
    {
      field: "show_for_opportunities",
      headerName: "Active for Opportunities",
      flex: 1,
      minWidth: 150,
      type: "boolean",
      editable: true,
    },
    {
      field: "update",
      headerName: "Update",
      flex: 1,
      minWidth: 50,
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => handleUpdateIndustry(params.row)}
          disabled={isLoading || !hasChanges(params.row) || params.row.discarded_at}
        >
          Save
        </Button>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          disabled={Boolean(!params.row.discarded_at)}
          label="Restore"
          onClick={() => restoreIndustry(params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          disabled={Boolean(params.row.discarded_at)}
          label="Discard"
          onClick={() => deleteIndustry(params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Recommended Campaigns"
          onClick={() => handleOpenRecommendedCampaignsModal(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Associated User"
          onClick={() => handleOpenAssociatedUserModal(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Bookmark Folders"
          onClick={() => handleOpenIndustryBookmarkFolderModal(params.row)}
          showInMenu
        />,
        <GridActionsCellItem label="NAICS Codes" onClick={() => handleOpenNaicsCodesModal(params.row)} showInMenu />,
      ],
      minWidth: 100,
    },
  ]

  const [rows, setRows] = useState<GridRowsProp>()

  useEffect(() => {
    setRows(
      industries.map((industry) => ({
        ...industry,
        name: industry.discarded_at ? `${industry.name} (Discarded)` : industry.name,
        created_at: formatDatetime(industry.created_at),
        updated_at: formatDatetime(industry.updated_at),
        discarded_at: industry.discarded_at ? formatDatetime(industry.discarded_at) : "",
      })),
    )
  }, [industries])

  const setFilterParams = useSetUrlParamState<typeof filterParams>()

  const handlePaginationChange = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
    setFilterParams({
      ...filterParams,
      page: paginationModel.page,
      count: paginationModel.pageSize,
    })
  }

  const handleSortingChange = (sortingModel: GridSortModel) => {
    setSortingModel(sortingModel)
    setPaginationModel({ ...paginationModel, page: 0 })
    setFilterParams({
      ...filterParams,
      sort_by: sortingModel[0]?.field,
      sort_order: sortingModel[0]?.sort || "asc",
      page: 0,
    })
  }

  return (
    <Paper sx={{ marginTop: 2, width: "100%" }}>
      <Box
        sx={{
          [`.${gridClasses.cell}.discarded`]: {
            backgroundColor: "#f0f0f0",
            color: "#666",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
          }}
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            return params.row.discarded_at ? "discarded" : ""
          }}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          sortingMode="server"
          paginationModel={paginationModel}
          columnVisibilityModel={columnVisibilityModel}
          isCellEditable={(params) => !params.row.discarded_at}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          sortModel={sortingModel}
          autoHeight={true}
          onSortModelChange={handleSortingChange}
          onPaginationModelChange={handlePaginationChange}
          rowCount={totalCount ?? -1}
        />
      </Box>
    </Paper>
  )
}

import { Link as MuiLink } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"

export const userOrgSharesColumns: GridColDef[] = [
  {
    display: "flex",
    field: "meeting_title",
    flex: 1,
    headerName: "Meeting Title",
  },
  {
    display: "flex",
    field: "channel_name",
    flex: 1,
    headerName: "Channel Name",
  },
  {
    display: "flex",
    field: "channel_location",
    flex: 1,
    headerName: "Channel Location",
  },
  {
    display: "flex",
    field: "share_path",
    flex: 1,
    headerName: "Share Link",
    renderCell: (params) => {
      return (
        <MuiLink component={Link} to={`/${params.row.share_path}`}>
          Share Link
        </MuiLink>
      )
    },
    sortable: false,
  },
  {
    display: "flex",
    field: "created_at",
    flex: 1,
    headerName: "Created At",
  },
  {
    display: "flex",
    field: "user_email",
    flex: 1,
    headerName: "User Email",
  },
]

import { Stack } from "@mui/material"
import { FC } from "react"
import { CampaignFields } from "@src/components/Opportunities/CampaignFields"
import { FindOpportunitiesFilter } from "@src/components/Opportunities/DataFilters/FindOpportunitiesFilter"
import { OpportunityChannelFilter } from "@src/components/Opportunities/DataFilters/OpportunityChannelFilter"
import { OpportunityGeographiesFilters } from "@src/components/Opportunities/DataFilters/OpportunityGeographiesFilters"
import { OpportunityIndustryFilter } from "@src/components/Opportunities/DataFilters/OpportunityIndustryFilter"
import { OpportunitySearch } from "@components/Opportunities/DataFilters/OpportunitySearch"

export const OpportunitiesDataFiltersContainer: FC = () => {
  return (
    <Stack gap={1}>
      <FindOpportunitiesFilter />
      <OpportunityIndustryFilter />
      <Stack direction="row" gap={1} flexWrap="wrap">
        <OpportunityGeographiesFilters />
        <OpportunityChannelFilter />
      </Stack>
      <Stack direction="row" gap={1}>
        <CampaignFields />
      </Stack>
    </Stack>
  )
}

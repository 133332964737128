import { GridColDef } from "@mui/x-data-grid"

export const domainColumns: GridColDef[] = [
  { field: "id", type: "number", headerName: "ID", flex: 0.25 },
  { field: "domain", headerName: "Domain", flex: 1, type: "string", editable: true },
  {
    field: "created_at",
    headerName: "Created",
    flex: 0.5,
    type: "dateTime",
    valueGetter: (value) => new Date(value as string),
    renderCell: (params) => new Date(params.value as string).toLocaleString(),
  },
  { field: "notes", headerName: "Notes", flex: 1, type: "string", editable: true },
  {
    field: "max_concurrent_downloads",
    headerName: "Max Concurrent Downloads",
    flex: 0.5,
    type: "number",
    editable: true,
  },
  { field: "total", headerName: "Total", flex: 0.5, type: "number" },
  {
    field: "successRate",
    headerName: "Success Rate",
    flex: 0.5,
    valueGetter: (value) => {
      if (value === 0) return 0
      if (!value) return undefined
      return value * 100
    },
    valueFormatter: (value) => {
      return value !== undefined ? `${(value as number).toFixed(2)}%` : ""
    },
  },
]

import { Button, TableCell, TextField, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useUpdatePerson } from "@api/admin/persons"
import { useUpdateVoicePersonId } from "@api/admin/voices"
import { Person } from "@src/interfaces/person"
import { AdminContact } from "@src/interfaces/contact"

export const PersonCells: FC<{
  // if voice_id is not null, update the voice with the person_id
  voiceId: number | null
  personId: number | null
  contactData?: AdminContact
  personsMap?: Map<number, Person<AdminContact>>
}> = ({ voiceId, personId, contactData, personsMap }) => {
  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [organization, setOrganization] = useState("")

  const { mutate: updateVoicePersonId, isLoading: voiceIsLoading } = useUpdateVoicePersonId()
  const { mutate: updatePerson, isLoading: personIsLoading } = useUpdatePerson()

  let canUpdate = Boolean(voiceId)
  if (!voiceId && personId && personsMap) {
    const person = personsMap.get(personId)
    if (person) {
      canUpdate = name !== person.name || title !== person.title || organization !== person.organization
    }
  }

  useEffect(() => {
    if (personId && personsMap) {
      const person = personsMap.get(personId)
      const personContact = person?.contact
      if (personContact) {
        setName(person.contact?.first_name + " " + person.contact?.last_name)
        setTitle(person.contact?.title || "")
        setOrganization(person.organization)
      } else if (person) {
        setName(person.name)
        setTitle(person.title)
        setOrganization(person.organization)
      }
    }
  }, [personId, personsMap])

  return (
    <>
      <TableCell>
        {contactData ? (
          <Typography>{name}</Typography>
        ) : (
          <TextField value={name} onChange={(event) => setName(event.target.value)} size="small" />
        )}
      </TableCell>
      <TableCell>
        {contactData ? (
          <Typography>{title}</Typography>
        ) : (
          <TextField value={title} onChange={(event) => setTitle(event.target.value)} size="small" />
        )}
      </TableCell>
      <TableCell>
        {contactData ? (
          <Typography>{organization}</Typography>
        ) : (
          <TextField value={organization} onChange={(event) => setOrganization(event.target.value)} size="small" />
        )}
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          disabled={!canUpdate || voiceIsLoading || personIsLoading || Boolean(contactData)}
          onClick={() => {
            if (voiceId !== null && personId !== null) {
              updateVoicePersonId({
                voice_id: voiceId,
                person_id: personId,
              })
            }
            if (personId !== null) {
              updatePerson({ id: personId, name, title, organization })
            }
          }}
        >
          Update
        </Button>
      </TableCell>
    </>
  )
}

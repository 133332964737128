import { useFetchGeneratedByModelList } from "@api/admin/opportunities"
import { Autocomplete, FormControl, TextField } from "@mui/material"
import { FC, useState } from "react"

export interface GeneratedByModelFilterParams {
  urlParamState: unknown
  setUrlParamState: (newFilterParams: Record<string, unknown>) => void
}

export interface UrlStateProps {
  generated_by_model: string
  page: number
}

export const GeneratedByModelFilter: FC<GeneratedByModelFilterParams> = (params) => {
  const urlParamState = params.urlParamState as UrlStateProps
  const setUrlParamState = params.setUrlParamState
  const [modelType, setModelType] = useState<string | null>(urlParamState.generated_by_model || null)

  const { data } = useFetchGeneratedByModelList()

  const handleOnChange = (event: React.SyntheticEvent, value: string | null) => {
    setModelType(value)
    setUrlParamState({
      ...urlParamState,
      generated_by_model: value,
      page: 0,
    })
  }

  if (data && data.length > 0) {
    return (
      <FormControl sx={{ minWidth: 200 }}>
        <Autocomplete
          onChange={handleOnChange}
          options={data}
          renderInput={(params) => <TextField {...params} label="Generated By Model" />}
          value={modelType}
        />
      </FormControl>
    )
  }
  return null
}

import { OpportunitiesContainerContext } from "@src/containers/OpportunitiesContainers/OpportunitiesContainer"
import { ChannelSelectorMultiple } from "@src/components/shared/Selectors/Multiple/Channel"
import { Dispatch, FC, SetStateAction, useContext } from "react"
import { useFetchOrganizations } from "@src/api/organizations"
import { FilterParams } from "@src/components/Home/interface"

export const OpportunityChannelFilter: FC = () => {
  const { params, urlParamState, setUrlParamState } = useContext(OpportunitiesContainerContext)
  const { data: organizationsData, isLoading: organizationsLoading } = useFetchOrganizations()

  const campaignId = params.get("campaign") ?? ""
  const setUrlParamStateWithPage0 = ((newState: typeof urlParamState) =>
    setUrlParamState({ ...newState, campaign: campaignId ? `${campaignId}` : undefined, page: 0 })) as Dispatch<
    SetStateAction<FilterParams>
  >

  return (
    <ChannelSelectorMultiple
      options={organizationsData ? organizationsData.organizations : []}
      filterParams={urlParamState as FilterParams}
      loading={organizationsLoading}
      setFilterParams={setUrlParamStateWithPage0}
      sx={{ flex: "30ch 1 0" }}
    />
  )
}

import React from "react"
import ReactDOM from "react-dom"
import { Helmet } from "react-helmet"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "@mui/material"

import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import Auth0ProviderWithHistory from "./auth/auth0ProviderWithHistory"
import { ToastProvider } from "./components/shared/ToastHook"
import { OpenModalProvider } from "./components/shared/OpenModalHook"
import { ConfirmationModalProvider } from "./stories/ConfirmationModal"
import { theme } from "./theme"
import { AxiosProvider } from "./api/axiosInstance"

import { initializeSentry } from "./initializers"

initializeSentry()

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Barlow:400,500,600,700,800&display=swap" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <title>Cloverleaf.AI</title>
    </Helmet>
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <OpenModalProvider>
          <ConfirmationModalProvider>
            <BrowserRouter>
              <Auth0ProviderWithHistory>
                <AxiosProvider>
                  <App />
                </AxiosProvider>
              </Auth0ProviderWithHistory>
            </BrowserRouter>
          </ConfirmationModalProvider>
        </OpenModalProvider>
      </ToastProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

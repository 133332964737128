import { useUpdateFineTuning } from "@api/admin/opportunities"
import { Button } from "@mui/material"
import {
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarProps,
} from "@mui/x-data-grid"
import { Dispatch, SetStateAction, useEffect } from "react"

export const Toolbar = (props: GridToolbarProps) => {
  const rowSelectionModel = props.rowSelectionModel as GridRowSelectionModel
  const setSelectionModel = props.setRowSelectionModel as Dispatch<SetStateAction<GridRowSelectionModel>>
  const showButtons = rowSelectionModel?.length > 0 ? "flex" : "none"
  const { mutate: updateFineTuning, isSuccess: successUpdateFineTuning } = useUpdateFineTuning()

  const handleFineTuningClick = (fine_tuning: boolean) => {
    updateFineTuning({ briefing_ids: rowSelectionModel as number[], fine_tuning })
  }

  useEffect(() => {
    if (successUpdateFineTuning) {
      setSelectionModel([])
    }
  }, [successUpdateFineTuning])

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <Button onClick={() => handleFineTuningClick(true)} sx={{ display: showButtons }}>
        Enable Fine Tuning
      </Button>
      <Button onClick={() => handleFineTuningClick(false)} sx={{ display: showButtons }}>
        Disable Fine Tuning
      </Button>
    </GridToolbarContainer>
  )
}

import {
  OpportunityQueryObject,
  OpportunityQueryObjectWithCampaign,
  urlParamsToOpportunityQueryObject,
} from "@api/opportunities"
import { Layout } from "@components/shared/Layout"
import useSetUrlParamState from "@components/shared/UseSetUrlParamState"
import { createContext, Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { OpportunitiesDataFiltersContainer } from "./OpportunitiesDataFiltersContainer"
import { OpportunitiesDataGrid } from "@components/Opportunities/DataGrid/OpportunitiesDataGrid"
import { Box } from "@mui/material"

export interface OppportunitiesContainerContextProps {
  params: URLSearchParams
  urlParamState: OpportunityQueryObjectWithCampaign
  setUrlParamState: (newFilterParams: OpportunityQueryObjectWithCampaign) => void
  terms: Set<string>
  setTerms: Dispatch<SetStateAction<Set<string>>>
}

export const OpportunitiesContainerContext = createContext<OppportunitiesContainerContextProps>({
  params: new URLSearchParams(),
  urlParamState: {
    states: [],
    cities: [],
    counties: [],
    organizations: [],
    naics_code: [],
    industry: [],
    channel_types: [],
    terms: [],
    sort: "",
    order: "",
    page: 0,
    count: 25,
    fine_tuning: "",
    generated_by_model: "",
    campaign: "",
  },
  setUrlParamState: () => {
    /* Something here for linter */
  },
  terms: new Set<string>(),
  setTerms: () => {
    /* Something here for linter */
  },
})

export const OpportunitiesContainer: FC = () => {
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)
  const urlParamState = urlParamsToOpportunityQueryObject(params)
  const setUrlParamState = useSetUrlParamState<OpportunityQueryObjectWithCampaign>()

  const [terms, setTerms] = useState(new Set<string>(urlParamState.terms))
  useEffect(() => {
    setTerms(new Set<string>(urlParamState.terms))
  }, [paramsString])

  return (
    <Layout title="Opportunities">
      <OpportunitiesContainerContext.Provider value={{ params, urlParamState, setUrlParamState, terms, setTerms }}>
        <OpportunitiesDataFiltersContainer />
        <Box marginTop={2}>
          <OpportunitiesDataGrid />
        </Box>
      </OpportunitiesContainerContext.Provider>
    </Layout>
  )
}

import { FC } from "react"
import { Context } from "./CustomMade/Context"
import { TableContainer } from "./CustomMade/TableContainer"

export const Container: FC = () => {
  return (
    <Context>
      <TableContainer />
    </Context>
  )
}

import { BookmarksAdminDataGrid } from "@components/Admin/UserOrganizations/BookmarksAdminDataGrid/BookmarksAdminDataGrid"
import { Box } from "@mui/material"
import { createContext, FC } from "react"
import { useParams } from "react-router-dom"

export interface BookmarksAdminContainerContextProps {
  userOrgId: number
}

export const BookmarksAdminContainerContext = createContext<BookmarksAdminContainerContextProps>({
  userOrgId: 1,
})

export const BookmarksAdminContainer: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const userOrgId = userOrganizationId ? parseInt(userOrganizationId) : 1

  return (
    <Box margin={1}>
      <BookmarksAdminContainerContext.Provider value={{ userOrgId }}>
        <BookmarksAdminDataGrid />
      </BookmarksAdminContainerContext.Provider>
    </Box>
  )
}

import { GridColDef } from "@mui/x-data-grid"

export const userOrgBookmarksColumns: GridColDef[] = [
  {
    display: "flex",
    field: "meeting_title",
    flex: 1,
    headerName: "Meeting Title",
  },
  {
    display: "flex",
    field: "notes",
    flex: 1,
    headerName: "Notes",
  },
  {
    display: "flex",
    field: "channel_name",
    flex: 1,
    headerName: "Channel Name",
  },
  {
    display: "flex",
    field: "created_at",
    flex: 1,
    headerName: "Created At",
  },
  {
    display: "flex",
    field: "user_email",
    flex: 1,
    headerName: "User Email",
  },
]
